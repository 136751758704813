export const typeHazards = [
  {
    id: 'uxo',
    name: 'Unexploded Ordanance (UXO)',
    description: 'Explosive weapons posing hazards. Reporting of UXOs and UXO 9-Line.',
    active: "true",
    items: [
      {
        id: 'antiPersonnelMine',
        name: 'Anti-Personnel Mine',
        description:
          'Explosive devices designed to be detonated by the presence, proximity, or contact of a person.',
      },
      {
        id: 'antiTankMine',
        name: 'Anti-Tank Mine',
        description:
          'Explosive devices intended to damage or destroy vehicles, including tanks and armored fighting vehicles.',
      },
      // { id: 'bomb', name: 'Bomb', description: 'Unexploded aerial bombs or large explosive devices that failed to detonate upon impact.'},
      // { id: 'boobyTrap', name: 'Booby Trap', description: 'Concealed or camouflaged devices designed to cause harm when disturbed or triggered unintentionally.'},
      // { id: 'clusterMunition', name: 'Cluster Munition', description: 'Explosive weapons that release or eject smaller submunitions, often remaining unexploded and hazardous.'},
      {
        id: 'droppedDispenser',
        name: 'Dropped Dispenser',
        description:
          'Aerial dispensers designed to release submunitions or payloads, which may not have functioned properly.',
      },
      {
        id: 'droppedSubmunition',
        name: 'Dropped Submunition',
        description:
          'Small explosive devices released from dispensers or cluster munitions that failed to detonate.',
      },
      // { id: 'explosiveRemnantsOfWar',name: 'Explosive Remnants of War (ERW)',description: 'Unexploded ordnance and abandoned explosive weapons left after an armed conflict.'},
      {
        id: 'generalPurposeBomb',
        name: 'General Purpose Bomb',
        description:
          'Standard explosive bombs used against various targets, potentially unexploded if they failed to detonate.',
      },
      {
        id: 'generalPurposeChemicalBomb',
        name: 'General Purpose Chemical Bomb',
        description:
          'Bombs designed to disperse chemical agents, posing additional chemical hazards if unexploded.',
      },
      {
        id: 'grenade',
        name: 'Grenade',
        description: 'Hand-thrown explosive devices that have not exploded.',
      },
      // { id: 'ied',name: 'Improvised Explosive Device (IED)',description: 'Homemade bombs constructed and deployed in unconventional ways.'},
      // { id: 'landmine',name: 'Landmine',description: 'Explosive devices concealed under or on the ground, designed to detonate when triggered by a vehicle or person.'},
      {
        id: 'missile',
        name: 'Missile',
        description:
          'Unexploded guided weapons designed to deliver explosive payloads over long distances.',
      },
      {
        id: 'mortar',
        name: 'Mortar',
        description:
          'Unexploded mortar shells that failed to detonate upon impact.',
      },
      {
        id: 'projectile',
        name: 'Projectile',
        description:
          'Unexploded artillery shells or other fired munitions that did not detonate.',
      },
      {
        id: 'rifleGrenade',
        name: 'Rifle Grenade',
        description:
          'Grenades designed to be launched from a rifle, potentially unexploded upon landing.',
      },
      {
        id: 'rocket',
        name: 'Rocket',
        description:
          'Unguided self-propelled weapons that have failed to detonate.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other unexploded ordnance not listed.',
      },
    ],
  },
  {
    id: 'militaryActivity',
    name: 'Military Activity',
    description: 'Operations conducted by armed forces.',
    active: "true",
    items: [
      { id: 'peacekeepingMissions', name: 'Peacekeeping Missions', description: 'Deployment to maintain or restore peace in conflict areas.' },
      { id: 'combatOperations', name: 'Combat Operations', description: 'Engagements between opposing military forces.' },
      { id: 'reconAndSurveillance', name: 'Reconnaissance and Surveillance', description: 'Gathering information about the enemy or environment.' },
      { id: 'militaryTrainingExercise', name: 'Military Training and Exercises', description: 'Activities to train military personnel.' },
      { id: 'hadr', name: 'Humanitarian Aid and Disaster Relief (HA/DR)', description: 'Military assistance during humanitarian crises.' },
      { id: 'securityPatrols', name: 'Security Patrols', description: 'Routine patrols to maintain security.' },
      { id: 'counterTerrorismOperations', name: 'Counterterrorism Operations', description: 'Actions to prevent or respond to terrorist activities.' },
      { id: 'cyberWarfare', name: 'Cyber Warfare', description: 'Military operations in cyberspace.' },
      { id: 'logistics', name: 'Logistics and Support', description: 'Planning and carrying out movement and maintenance of forces.' },
      { id: 'eod', name: 'Demining and Explosive Ordnance Disposal (EOD)', description: 'Detection and disposal of explosive devices.' },
      { id: 'psyops', name: 'Psychological Operations (Psyops)', description: 'Operations to influence perceptions and behavior.' },
      { id: 'searchAndRescue', name: 'Search and Rescue Operations', description: 'Efforts to find and help people in distress.' },
      { id: 'navalBlockade', name: 'Naval Blockades', description: 'Use of naval forces to prevent access to certain areas.' },
      { id: 'airDefenseOperations', name: 'Air Defense Operations', description: 'Protecting against enemy aircraft or missiles.' },
      { id: 'counterinsurgencyOperations', name: 'Counterinsurgency Operations', description: 'Actions taken to defeat insurgency.' },
      { id: 'electronicWarfare', name: 'Electronic Warfare', description: 'Use of electromagnetic spectrum in military operations.' },
      { id: 'intelligenceOperations', name: 'Military Intelligence Operations', description: 'Collection and analysis of information to support military activities.' },
      { id: 'antiPiracyOperations', name: 'Anti-Piracy Operations', description: 'Efforts to prevent piracy at sea.' },
      { id: 'specialForcesOperations', name: 'Special Forces Operations', description: 'Missions conducted by specially trained units.' },
      { id: 'droneStrikes', name: 'Drone Strikes', description: 'Use of unmanned aerial vehicles to attack targets.' },
      { id: 'droneSurveillance', name: 'Drone Surveillance', description: 'Use of drones for reconnaissance.' },
      { id: 'ambush', name: 'Ambush', description: 'Surprise attack from a concealed position.' },
      { id: 'sead', name: 'Suppression of Enemy Air Defenses (SEAD)', description: 'Neutralizing enemy air defense systems.' },
      { id: 'militaryAdvisoryTraining', name: 'Military Advisory and Training Missions', description: 'Assisting other nations in military training.' },
      { id: 'armsControlDisarmament', name: 'Arms Control and Disarmament Efforts', description: 'Reducing or eliminating weapons.' },
      { id: 'medicalServices', name: 'Medical Services', description: 'Providing medical care in military contexts.' },
      { id: 'engineeringConstruction', name: 'Engineering and Construction', description: 'Building or repairing infrastructure.' },
      { id: 'nuclearDeterrence', name: 'Nuclear Deterrence Operations', description: 'Maintaining nuclear capabilities to deter aggression.' },
      { id: 'jointMultinationalOps', name: 'Joint Multinational Operations', description: 'Military operations involving multiple nations.' },
      { id: 'counterNarcotics', name: 'Counter-Narcotics Operations', description: 'Efforts to disrupt illegal drug trade.' },
      { id: 'maritimeSecurity', name: 'Maritime Security Operations', description: 'Ensuring safety and security at sea.' },
      { id: 'airborneOps', name: 'Airborne Operations', description: 'Military operations involving air transport and insertion.' },
      { id: 'amphibiousWarfare', name: 'Amphibious Warfare Operations', description: 'Military operations launched from the sea by naval forces.' },
      { id: 'infantryEngagements', name: 'Infantry Engagements', description: 'Ground combat involving infantry units.' },
      { id: 'armoredWarfare', name: 'Armored Warfare', description: 'Use of armored fighting vehicles in combat.' },
      { id: 'artilleryStrikes', name: 'Artillery Strikes', description: 'Use of large-caliber guns to support military operations.' },
      { id: 'aerialDogfights', name: 'Aerial Dogfights', description: 'Air combat between fighter aircraft.' },
      { id: 'navalEngagements', name: 'Naval Engagements', description: 'Combat between naval forces.' },
      { id: 'submarineWarfare', name: 'Submarine Warfare', description: 'Use of submarines in military operations.' },
      { id: 'urbanCombat', name: 'Urban Combat', description: 'Military operations conducted in urban environments.' },
      { id: 'guerrillaWarfare', name: 'Guerrilla Warfare', description: 'Irregular warfare by small groups using tactics like ambushes.' },
      { id: 'tunnelWarfare', name: 'Tunnel Warfare', description: 'Use of underground tunnels for military operations.' },
      { id: 'mountainWarfare', name: 'Mountain Warfare', description: 'Military operations conducted in mountainous terrain.' },
      { id: 'jungleWarfare', name: 'Jungle Warfare', description: 'Military operations in jungle environments.' },
      { id: 'desertWarfare', name: 'Desert Warfare', description: 'Military operations in desert conditions.' },
      { id: 'arcticWarfare', name: 'Arctic Warfare', description: 'Military operations in cold, snowy environments.' },
      { id: 'siegeOperations', name: 'Siege Operations', description: 'Surrounding and isolating a target to force surrender.' },
      { id: 'precisionStrikes', name: 'Precision Strikes', description: 'Targeted attacks aimed at specific objectives.' },
      { id: 'carrierStrikeOperations', name: 'Carrier Strike Operations', description: 'Military actions involving aircraft carriers.' },
      { id: 'strategicBombing', name: 'Strategic Bombing', description: 'Bombing aimed at destroying the enemy\'s economic ability to fight.' },
      { id: 'antiSubmarineWarfare', name: 'Anti-Submarine Warfare', description: 'Operations to detect and destroy enemy submarines.' },
      { id: 'antiAirWarfare', name: 'Anti-Air Warfare', description: 'Defensive measures against enemy aircraft.' },
      { id: 'cbrnWarfare', name: 'Chemical, Biological, Radiological, and Nuclear Warfare (CBRN)', description: 'Use of weapons of mass destruction.' },
      { id: 'informationWarfare', name: 'Information Warfare', description: 'Operations to achieve information superiority.' },
      { id: 'closeAirSupport', name: 'Close Air Support', description: 'Air action against hostile targets in close proximity to friendly forces.' },
      { id: 'searchAndDestroy', name: 'Search and Destroy Missions', description: 'Operations to find and eliminate enemy forces.' },
      { id: 'decapitationStrikes', name: 'Decapitation Strikes', description: 'Targeting leadership or command structures.' },
      { id: 'militaryCheckpoint', name: 'Checkpoint', description: 'Controlled point for inspection and control of movement.' },
      { id: 'ieds', name: 'Improvised Explosive Devices (IEDs)', description: 'Homemade bombs used to inflict casualties.' },
      { id: 'uavOperations', name: 'Unmanned Aerial Vehicle (UAV) Operations', description: 'Use of drones for surveillance or combat.' },
      { id: 'falseSurrenders', name: 'False Surrenders', description: 'Feigning surrender to attack the enemy.' },
      { id: 'other', name: 'Other (specify in the comment)', description: 'Any other military activity not listed.' },
    ],
  },
  {
    id: 'warCrime',
    name: 'War Crime',
    description: 'Violations of international humanitarian law during armed conflicts.',
    active: "true",
    items: [
      {
        id: 'killingOfCivilians',
        name: 'Killing of Civilians',
        description:
          'Intentional killing of non-combatant civilians in conflict zones.',
      },
      {
        id: 'torture',
        name: 'Torture',
        description:
          'Inflicting severe pain or suffering for punishment or coercion.',
      },
      {
        id: 'sexualAssaultWar',
        name: 'Sexual Assault',
        description:
          'Sexual violence committed against individuals during armed conflict.',
      },
      {
        id: 'forcedDeportation',
        name: 'Forced Deportation',
        description:
          'Compelling individuals to leave their homes without legal grounds.',
      },
      {
        id: 'attackOnCivilians',
        name: 'Attack on Civilians',
        description:
          'Military operations directed against civilian populations.',
      },
      {
        id: 'attackProtectedSite',
        name: 'Attack on Protected Site',
        description:
          'Targeting locations that are protected under international law.',
      },
      {
        id: 'childSoldiers',
        name: 'Child Soldiers',
        description:
          'Recruitment or use of children under the age of 18 in hostilities.',
      },
      {
        id: 'starvationWarfare',
        name: 'Starvation as Warfare',
        description:
          'Deliberate deprivation of essential resources to cause starvation.',
      },
      {
        id: 'humanShield',
        name: 'Human Shield',
        description:
          'Using civilians to deter attacks on combatants or military objectives.',
      },
      {
        id: 'attackMedicalPersonnel',
        name: 'Attack on Medical Personnel',
        description:
          'Violence against doctors, nurses, or medical staff in conflict zones.',
      },
      {
        id: 'attackMediaPersonnel',
        name: 'Attack on Media Personnel',
        description:
          'Targeting journalists or media workers covering conflicts.',
      },
      {
        id: 'chemicalWarfareWar',
        name: 'Chemical Warfare Agents',
        description:
          'Use of chemical substances to cause harm or death in warfare.',
      },
      {
        id: 'denialFairTrial',
        name: 'Denial of Fair Trial',
        description:
          'Refusal to provide legal proceedings that meet international standards.',
      },
      {
        id: 'lootingCivilianProperty',
        name: 'Looting Civilian Property',
        description: 'Theft or plundering of property belonging to civilians.',
      },
      {
        id: 'indiscriminateAttack',
        name: 'Indiscriminate Attack',
        description:
          'Attacks that are not directed at a specific military target.',
      },
      {
        id: 'prohibitedWeapons',
        name: 'Use of Prohibited Weapons',
        description: 'Deployment of weapons banned under international law.',
      },
      {
        id: 'forcedLabor',
        name: 'Forced Labor',
        description:
          'Compelling individuals to work against their will under threat.',
      },
      {
        id: 'attackCulturalSites',
        name: 'Attack on Cultural Heritage Sites',
        description:
          'Destruction or damage to sites of cultural or historical significance.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other war crime not listed.',
      },
    ],
  },
  {
    id: 'criminalActivity',
    name: 'Criminal Activity',
    description: 'Acts that violate the law and can be prosecuted by the state.',
    active: "true",
    items: [
      {
        id: 'riot',
        name: 'Riot',
        description: 'A violent disturbance of the peace by a crowd.',
      },
      {
        id: 'murder',
        name: 'Homicide',
        description: 'Unlawful killing of one person by another.',
      },
      {
        id: 'rape',
        name: 'Sexual Assault',
        description: 'Any non-consensual sexual act or behavior.',
      },
      {
        id: 'kidnapping',
        name: 'Kidnapping',
        description: 'Abducting someone and holding them captive.',
      },
      {
        id: 'arson',
        name: 'Arson',
        description: 'Deliberately setting fire to property.',
      },
      {
        id: 'robbery',
        name: 'Robbery',
        description:
          'Taking property unlawfully from a person or place by force or threat.',
      },
      {
        id: 'shooting',
        name: 'Shooting',
        description: 'Discharging a firearm causing harm or danger.',
      },
      {
        id: 'bombing',
        name: 'Bombing',
        description: 'Detonation of an explosive device.',
      },
      {
        id: 'assault',
        name: 'Assault',
        description: 'Physical attack or threat of attack.',
      },
      {
        id: 'hate',
        name: 'Hate Crime',
        description: 'Criminal acts motivated by bias or prejudice.',
      },
      {
        id: 'carJacking',
        name: 'Carjacking',
        description: 'Stealing a car by force or threat of force.',
      },
      {
        id: 'home',
        name: 'Home Invasion',
        description:
          'Illegal entry into a residence with intent to commit a crime.',
      },
      {
        id: 'gangViolence',
        name: 'Gang Violence',
        description: 'Criminal activities conducted by organized gangs.',
      },
      {
        id: 'humanTrafficking',
        name: 'Human Trafficking',
        description:
          'Illegal trade of humans for exploitation or commercial gain.',
      },
      {
        id: 'looting',
        name: 'Looting',
        description: 'Stealing goods during a disaster or riot.',
      },
      {
        id: 'cybercrime',
        name: 'Cybercrime',
        description:
          'Criminal activities carried out using computers or the internet.',
      },
      {
        id: 'drugTrafficking',
        name: 'Drug Trafficking',
        description: 'Illegal production, transportation, and sale of drugs.',
      },
      {
        id: 'fraud',
        name: 'Fraud',
        description:
          'Wrongful deception intended to result in financial or personal gain.',
      },
      {
        id: 'extortion',
        name: 'Extortion',
        description: 'Obtaining something through force or threats.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other criminal activity not listed.',
      },
    ],
  },
  {
    id: 'structureHazard',
    name: 'Structure Hazard',
    description: 'Hazards related to buildings and other structures.',
    active: "true",
    items: [
      {
        id: 'structuralDamage',
        name: 'Structural Damage',
        description: 'Damage compromising the integrity of a structure.',
      },
      {
        id: 'structureFire',
        name: 'Structure Fire',
        description: 'Fire occurring within a building or structure.',
      },
      {
        id: 'downPowerLine',
        name: 'Down Power Line',
        description: 'Electrical power lines that have fallen or are damaged.',
      },
      {
        id: 'waterHazard',
        name: 'Water Hazard',
        description: 'Hazardous accumulation or flow of water.',
      },
      {
        id: 'roadWashout',
        name: 'Road Washout',
        description: 'Erosion of a roadway due to water flow.',
      },
      {
        id: 'gasLeak',
        name: 'Gas Leak',
        description: 'Uncontrolled release of gas from a pipeline or storage.',
      },
      {
        id: 'collapse',
        name: 'Structure Collapse',
        description: 'Partial or complete failure of a building or structure.',
      },
      {
        id: 'sinkhole',
        name: 'Sinkhole',
        description:
          'Depression or hole in the ground caused by collapse of the surface layer.',
      },
      {
        id: 'structuralInstability',
        name: 'Structural Instability',
        description: 'Potential for a structure to fail or collapse.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other structure hazard not listed.',
      },
    ],
  },
  {
    id: 'naturalDisaster',
    name: 'Natural Disaster',
    description: 'Severe natural events causing damage or loss of life.',
    active: "true",
    items: [
      {
        id: 'flood',
        name: 'Flood',
        description: 'Overflow of water submerging land.',
      },
      {
        id: 'wildFire',
        name: 'Wildfire',
        description: 'Uncontrolled fire in vegetation areas.',
      },
      {
        id: 'hurricane',
        name: 'Hurricane',
        description: 'Tropical cyclone with strong winds and rain.',
      },
      {
        id: 'tornado',
        name: 'Tornado',
        description:
          'Violently rotating column of air extending from a thunderstorm to the ground.',
      },
      {
        id: 'landSlide',
        name: 'Landslide',
        description: 'Movement of rock, earth, or debris down a slope.',
      },
      {
        id: 'earthquake',
        name: 'Earthquake',
        description: 'Sudden shaking of the ground caused by seismic waves.',
      },
      {
        id: 'tsunami',
        name: 'Tsunami',
        description: 'Series of ocean waves caused by displacement of water.',
      },
      {
        id: 'volcanoes',
        name: 'Volcanic Eruption',
        description: 'Eruption of lava, ash, and gases from a volcano.',
      },
      {
        id: 'drought',
        name: 'Drought',
        description: 'Prolonged period of abnormally low rainfall.',
      },
      {
        id: 'avalanche',
        name: 'Avalanche',
        description: 'Rapid flow of snow down a slope.',
      },
      {
        id: 'extremeHeat',
        name: 'Heatwave',
        description: 'Extended period of excessively hot weather.',
      },
      {
        id: 'extremeCold',
        name: 'Cold Wave',
        description: 'Extended period of extremely cold weather.',
      },
      {
        id: 'blizzard',
        name: 'Blizzard',
        description: 'Severe snowstorm with strong winds.',
      },
      {
        id: 'hailStorm',
        name: 'Hailstorm',
        description: 'Storm producing hailstones.',
      },
      {
        id: 'sinkHole',
        name: 'Sinkhole',
        description: 'Depression caused by collapse of the ground surface.',
      },
      {
        id: 'sandStorm',
        name: 'Sandstorm',
        description: 'Strong wind carrying sand through the air.',
      },
      {
        id: 'severeStorm',
        name: 'Severe Storm',
        description:
          'Intense weather conditions including heavy rain, wind, or hail.',
      },
      {
        id: 'solarFlare',
        name: 'Solar Flare',
        description:
          'Eruption on the sun causing electromagnetic disturbances.',
      },
      {
        id: 'meteorStrike',
        name: 'Meteor Strike',
        description: "Impact of a meteorite on the Earth's surface.",
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other natural disaster not listed.',
      },
    ],
  },
  {
    id: 'wildlife',
    name: 'Wildlife',
    description: 'Hazards related to wild animals.',
    active: "true",
    items: [
      {
        id: 'alligatorCrocodile',
        name: 'Alligator/Crocodile',
        description: 'Presence of dangerous reptiles in the area.',
      },
      {
        id: 'bear',
        name: 'Bear',
        description: 'Sightings or encounters with bears.',
      },
      {
        id: 'bees',
        name: 'Bees',
        description: 'Swarm or infestation of bees.',
      },
      {
        id: 'boar',
        name: 'Boar',
        description: 'Presence of wild boars posing danger.',
      },
      {
        id: 'buffalo',
        name: 'Buffalo',
        description: 'Encounter with buffaloes.',
      },
      {
        id: 'dangerousCat',
        name: 'Dangerous Cat',
        description: 'Sightings of lions, tigers, or other large felines.',
      },
      {
        id: 'deerElkAntelope',
        name: 'Deer/Elk/Antelope',
        description:
          'Wild ungulates that may cause hazards, especially on roads.',
      },
      {
        id: 'elephant',
        name: 'Elephant',
        description: 'Presence of elephants posing risks.',
      },
      {
        id: 'hippopotamus',
        name: 'Hippopotamus',
        description: 'Sightings of hippos, which can be aggressive.',
      },
      {
        id: 'moose',
        name: 'Moose',
        description: 'Encounters with moose, potentially dangerous.',
      },
      {
        id: 'shark',
        name: 'Shark',
        description: 'Shark sightings or attacks in coastal areas.',
      },
      {
        id: 'wasps',
        name: 'Wasps / Hornets',
        description: 'Presence of wasp or hornet nests.',
      },
      {
        id: 'venomousSnakes',
        name: 'Venomous Snakes',
        description: 'Sightings of snakes that are poisonous.',
      },
      {
        id: 'dangerousInsects',
        name: 'Dangerous Insects',
        description:
          'Insects like scorpions or venomous spiders posing threats.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other wildlife hazard not listed.',
      },
    ],
  },
  {
    id: 'terroristActivity',
    name: 'Terrorist Activity',
    description: 'Actions intended to create fear and advance ideological goals.',
    active: "true",
    items: [
      {
        id: 'attacksOnCivilians',
        name: 'Attacks on Civilians',
        description: 'Violent acts targeting non-combatant civilians.',
      },
      {
        id: 'attacksOnInfrastructure',
        name: 'Attacks on Infrastructure',
        description:
          'Damage to facilities like power grids, bridges, or communications.',
      },
      {
        id: 'hostageTakingKidnapping',
        name: 'Hostage-taking and Kidnapping',
        description: 'Abduction of individuals to achieve political aims.',
      },
      {
        id: 'bombingTerrorist',
        name: 'Bombings and Explosions',
        description: 'Use of explosive devices to cause harm or destruction.',
      },
      {
        id: 'assassination',
        name: 'Assassinations and Targeted Killings',
        description: 'Deliberate killing of prominent individuals.',
      },
      {
        id: 'aircraftHijacking',
        name: 'Hijackings and Aircraft Attacks',
        description: 'Seizing control of vehicles, especially planes.',
      },
      {
        id: 'cyberAttack',
        name: 'Cyberattacks and Electronic Warfare',
        description: 'Disrupting digital systems to cause harm.',
      },
      {
        id: 'intimidation',
        name: 'Intimidation and Harassment',
        description: 'Using threats to influence or coerce.',
      },
      {
        id: 'recruitment',
        name: 'Recruitment and Radicalization',
        description: 'Enlisting individuals into extremist groups.',
      },
      {
        id: 'financing',
        name: 'Financing and Fundraising',
        description: 'Raising money to support terrorist activities.',
      },
      {
        id: 'propaganda',
        name: 'Propaganda and Dissemination of Ideology',
        description: 'Spreading extremist beliefs to gain support.',
      },
      {
        id: 'checkpointsTerrorist',
        name: 'Illegal Checkpoint',
        description: 'Unauthorized roadblocks set up by non-state actors.',
      },
      {
        id: 'nightLetters',
        name: 'Night Letters',
        description: 'Threatening messages delivered covertly.',
      },
      {
        id: 'useOfCBRNWeapons',
        name: 'Use of CBRN Weapons',
        description:
          'Employment of chemical, biological, radiological, or nuclear weapons.',
      },
      {
        id: 'insiderThreats',
        name: 'Insider Threats',
        description:
          'Individuals within an organization posing security risks.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other terrorist activity not listed.',
      },
    ],
  },
  {
    id: 'humanTrafficking',
    name: 'Human Trafficking',
    description: 'Trade of humans for exploitation or commercial gain.',
    active: "true",
    items: [
      {
        id: 'minorKidnapping',
        name: 'Kidnapping (Child/Teen)',
        description: 'Abduction of minors for exploitation.',
      },
      {
        id: 'adultKidnapping',
        name: 'Kidnapping (Adult)',
        description: 'Abduction of adults for trafficking purposes.',
      },
      {
        id: 'sexualAssault',
        name: 'Sexual Assault',
        description: 'Sexual exploitation of trafficked individuals.',
      },
      {
        id: 'prostitution',
        name: 'Prostitution',
        description: 'Forced involvement in sex work.',
      },
      {
        id: 'forcedMarriage',
        name: 'Forced Marriage',
        description: 'Coercing individuals into marriage without consent.',
      },
      {
        id: 'organTrafficking',
        name: 'Organ Trafficking',
        description: 'Illegal trade of human organs.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other human trafficking activity not listed.',
      },
    ],
  },
  {
    id: 'resourceScarcity',
    name: 'Resource Scarcity',
    description: 'Lack of essential resources needed for survival.',
    active: "true",
    items: [
      {
        id: 'fuelOutage',
        name: 'No Fuel',
        description: 'Shortage or complete lack of fuel supplies.',
      },
      {
        id: 'powerOutage',
        name: 'No Power',
        description: 'Loss of electrical power.',
      },
      {
        id: 'cellularOutage',
        name: 'No Cellular Service',
        description: 'Disruption or loss of mobile phone services.',
      },
      {
        id: 'foodOutage',
        name: 'No Food',
        description: 'Shortage of food supplies.',
      },
      {
        id: 'waterOutage',
        name: 'No Water',
        description: 'Lack of access to clean water.',
      },
      {
        id: 'loadShed',
        name: 'Load Shedding',
        description: 'Intentional shutdown of electric power to reduce load.',
      },
      {
        id: 'medicalSupplyShortage',
        name: 'Medical Supply Shortage',
        description: 'Insufficient medical supplies and equipment.',
      },
      {
        id: 'shelterShortage',
        name: 'Shelter Shortage',
        description: 'Lack of adequate housing or shelter.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other resource scarcity not listed.',
      },
    ],
  },
  {
    id: 'publicHealthHazard',
    name: 'Public Health Hazard',
    description: 'Threats to the health of the population.',
    active: "true",
    items: [
      {
        id: 'diseaseOutbreak',
        name: 'Disease Outbreak',
        description: 'Sudden increase in occurrences of a disease.',
      },
      {
        id: 'contaminatedWater',
        name: 'Contaminated Water Supply',
        description: 'Water supply polluted with harmful substances.',
      },
      {
        id: 'airbornePollutants',
        name: 'Airborne Pollutants',
        description: 'Harmful substances present in the air.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other public health hazard not listed.',
      },
    ],
  },
  {
    id: 'cyberThreat',
    name: 'Cyber Threat',
    description: 'Threats originating from digital activities.',
    active: "true",
    items: [
      {
        id: 'dataBreach',
        name: 'Data Breach',
        description: 'Unauthorized access to confidential data.',
      },
      {
        id: 'dosAttack',
        name: 'Denial of Service Attack',
        description: 'Overloading systems to disrupt services.',
      },
      {
        id: 'phishingScam',
        name: 'Phishing Scam',
        description: 'Fraudulent attempts to obtain sensitive information.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other cyber threat not listed.',
      },
    ],
  },
  {
    id: 'transportationHazard',
    name: 'Transportation Hazard',
    description: 'Accidents or incidents involving transportation.',
    active: "true",
    items: [
      { id: 'aircraftAccident', name: 'Aircraft Accident', description: 'Crashes or incidents involving aircraft.' },
      { id: 'automobileAccident', name: 'Autmobile Accident', description: 'Crashes or incidents involving motor vichles'},
      { id: 'maritimeAccident', name: 'Maritime Accident', description: 'Incidents at sea involving ships or boats.' },
      { id: 'trainDerailment', name: 'Train Derailment', description: 'Train accidents causing potential hazards.' },
      { id: 'other', name: 'Other (specify in the comment)', description: 'Any other transportation hazard not listed.' },
    ],
  },
  {
    id: 'environmentalHazard',
    name: 'Environmental Hazard',
    description: 'Threats to the environment and public safety.',
    active: "true",
    items: [
      {
        id: 'hazmatSpill',
        name: 'Hazardous Material Spill',
        description: 'Release of dangerous substances posing risks.',
      },
      {
        id: 'radiationLeak',
        name: 'Radiation Leak',
        description: 'Uncontrolled release of radioactive material.',
      },
      {
        id: 'oilSpill',
        name: 'Oil Spill',
        description:
          'Release of oil into the environment, especially marine areas.',
      },
      {
        id: 'other',
        name: 'Other (specify in the comment)',
        description: 'Any other environmental hazard not listed.',
      },
    ],
  },
  {
    id: 'nasa',
    name: 'NASA EONet Events',
    description: 'Natural events tracked by NASA\'s Earth Observatory Natural Event Tracker.',
    active: "true",
    items: [
      {
        id: 'wildfires',
        name: 'Wildfires',
        description: 'Uncontrolled fires in wilderness areas.',
      },
      {
        id: 'volcanoes',
        name: 'Volcanic Activity',
        description: 'Eruptions or increased activity of volcanoes.',
      },
      {
        id: 'severeStorms',
        name: 'Severe Storms',
        description: 'Intense storms tracked by NASA.',
      },
      {
        id: 'floods',
        name: 'Floods',
        description: 'Significant flooding events.',
      },
      {
        id: 'earthquakes',
        name: 'Earthquakes',
        description: 'Seismic events detected by NASA.',
      },
      {
        id: 'landslides',
        name: 'Landslides',
        description: 'Mass movement of earth or rock.',
      },
      {
        id: 'dustHaze',
        name: 'Dust and Haze',
        description: 'Atmospheric events reducing visibility.',
      },
      { id: 'snow', 
        name: 'Snow', 
        description: 'Significant snowfall events.' },
      { id: 'icebergs', 
        name: 'Icebergs', 
        description: 'Large pieces of freshwater ice that have broken off from glaciers.' 
      },
      { id: 'waterColor', 
        name: 'Water Color', 
        description: 'Changes in the color of water bodies, often due to algal blooms, sediment displacement, or pollution.' 
      },
      { id: 'seaLakeIce', 
        name: 'Sea and Lake Ice', 
        description: 'Large pieces of freshwater ice that have broken off from glaciers.' 
      },
      { id: 'manmade', 
        name: 'Manmade',
        description: 'Events caused by human activities, such as industrial accidents or environmental contamination.'
       }, 
      { id: 'drought', 
        ame: 'Drought', 
        description: 'Extended periods of below-average precipitation leading to water shortages.' 
      },  
      { id: 'temperatureExtremes', 
        name: 'Temperature Extremes',
        description: 'Events where temperatures significantly exceed normal ranges, either extremely hot or cold.' 
      },
      { id: 'other', 
        name: 'Other (specify in the comment)', 
        description: 'Any other NASA EONet event not listed.' },
    ],
  },
];
