import { getFirestore, collection, query, where, getDocs, onSnapshot  } from 'firebase/firestore';

const R = 6371; // Radius of the Earth in km

// Helper function to calculate Haversine distance
function haversineDistance(coords1, coords2) {
  const toRad = angle => (angle * Math.PI) / 180;

  const dLat = toRad(coords2.latitude - coords1.latitude);
  const dLng = toRad(coords2.longitude - coords1.longitude);

  const lat1 = toRad(coords1.latitude);
  const lat2 = toRad(coords2.latitude);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLng / 2) * Math.sin(dLng / 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return R * c * 1000; // Returns distance in meters
}

// Helper function to calculate bounding box for a radius
function getLatLngBounds(latitude, longitude, radiusInMeters) {
  const radiusInKm = radiusInMeters / 1000;

  const latDelta = (radiusInKm / R) * (180 / Math.PI);
  const lngDelta =
    (radiusInKm / (R * Math.cos((Math.PI * latitude) / 180))) *
    (180 / Math.PI);

  return {
    minLat: latitude - latDelta,
    maxLat: latitude + latDelta,
    minLng: longitude - lngDelta,
    maxLng: longitude + lngDelta,
  };
}

export function getNearbyReports(userLatitude, userLongitude, radius = 500, userId, callback) {
  const firestore = getFirestore();
  const reportsRef = collection(firestore, 'Reports');

  // Validate parameters
  if (!userLatitude || !userLongitude || !userId) {
      console.error('Latitude, longitude, or userId is undefined.');
      return;
  }

  // Get the bounding box coordinates
  const { minLat, maxLat, minLng, maxLng } = getLatLngBounds(
      userLatitude,
      userLongitude,
      radius
  );

  // Create a query for documents within the latitude range
  const latitudeQuery = query(
      reportsRef,
      where('reportType', '==', 'resourceScarcity'),
      where('latitude', '>=', minLat),
      where('latitude', '<=', maxLat)
  );

  // Set up a listener
  const unsubscribe = onSnapshot(latitudeQuery, async (snapshot) => {
      let reports = [];

      for (const doc of snapshot.docs) {
          const data = doc.data();
          const reportLat = data.latitude;
          const reportLng = data.longitude;

          // Check if latitude and longitude are defined
          if (reportLat === undefined || reportLng === undefined) {
              console.warn('Document missing latitude or longitude:', doc.id);
              continue;
          }

          // Check if longitude is within the range
          if (reportLng >= minLng && reportLng <= maxLng) {
              // Calculate distance
              const distance = haversineDistance(
                  { latitude: userLatitude, longitude: userLongitude },
                  { latitude: reportLat, longitude: reportLng }
              );

              if (distance <= radius) {
                  // Check if the user has already responded
                  const hazardUpdateRef = collection(firestore, `Reports/${doc.id}/HazardUpdate`);
                  const hazardUpdateQuery = query(hazardUpdateRef, where('submittedUserId', '==', userId));
                  const hazardUpdateSnapshot = await getDocs(hazardUpdateQuery);

                  // Include the report if the user hasn't responded
                  if (hazardUpdateSnapshot.empty) {
                      reports.push(data);
                  }
              }
          }
      }

      // Sort and limit reports
      reports.sort((a, b) => b.timeObserved?.toMillis() - a.timeObserved?.toMillis());
      if (reports.length > 5) {
          reports = reports.slice(0, 5);
      }

      // Call the callback function with new reports
      callback(reports);
  });

  // Return unsubscribe function to clean up the listener when necessary
  return unsubscribe;
}

  