// export async function getUserLocation() {
//   try {
//     if (navigator.geolocation) {
//       const permissionStatus = await navigator.permissions.query({
//         name: 'geolocation',
//       });
//       if (permissionStatus.state === 'granted') {
//         return new Promise((resolve, reject) => {
//           navigator.geolocation.getCurrentPosition(
//             position => {
//               resolve({
//                 lat: position.coords.latitude,
//                 lng: position.coords.longitude,
//               });
//             },
//             error => {
//               reject(error);
//             }
//           );
//         });
//       } else {
//         return fetchUserLocationByIP();
//       }
//     } else {
//       return fetchUserLocationByIP();
//     }
//   } catch (error) {
//     console.error('Error fetching user location:', error);
//     return null;
//   }
// }

// export async function fetchUserLocationByIP() {
//   try {
//     const response = await fetch('https://ipapi.co/json/');
//     const data = await response.json();
//     console.log("ip", data)
//     return { lat: data.latitude, lng: data.longitude };
//   } catch (error) {
//     console.error('Error fetching user location by IP:', error);
//     return null;
//   }
// }
export async function fetchUserLocationByIP() {
  try {
    const response = await fetch('https://ipinfo.io/json?token=f82c539a84acfc');
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const [lat, lng] = data.loc.split(',');
    
    return { lat: parseFloat(lat), lng: parseFloat(lng), source: 'ip' };
  } catch (error) {
    console.error('Error fetching user location by IP:', error.message || error);
    return null;
  }
}

export async function getUserLocation() {
  const locationByIP = await fetchUserLocationByIP();
  
  if (navigator.geolocation) {
    return new Promise((resolve, reject) => {
      // Return IP location first
      resolve(locationByIP);

      // Then try to fetch geolocation
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          resolve({ lat: latitude, lng: longitude, source: 'geo' });
        },
        (error) => {
          console.error('Error fetching geolocation:', error.message || error);
        },
        { timeout: 10000 }
      );
    });
  } else {
    // Fallback to IP-based location
    return locationByIP;
  }
}