import antipersonnel_mine_1 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_1.png';
import antipersonnel_mine_2 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_2.png';
import antipersonnel_mine_3 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_3.png';
import antipersonnel_mine_4 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_4.png';
import antipersonnel_mine_5 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_5.png';
import antipersonnel_mine_6 from '../assets/img/typesofbombs/Antipersonnel Mines/antipersonnel_mine_6.png';
import antitank_mine_1 from '../assets/img/typesofbombs/Antitank Mines/antitank_mine_1.png';
import antitank_mine_2 from '../assets/img/typesofbombs/Antitank Mines/antitank_mine_2.png';
import antitank_mine_3 from '../assets/img/typesofbombs/Antitank Mines/antitank_mine_3.png';
import antitank_mine_4 from '../assets/img/typesofbombs/Antitank Mines/antitank_mine_4.png';
import dropped_dispenser_1 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_1.png';
import dropped_dispenser_2 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_2.png';
import dropped_dispenser_4 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_4.png';
import dropped_dispenser_5 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_5.png';
import dropped_dispenser_6 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_6.png';
import dropped_dispenser_7 from '../assets/img/typesofbombs/Dropped Dispensers/dropped_dispenser_7.png';
import dropped_submunition_1 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_1.png';
import dropped_submunition_2 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_2.png';
import dropped_submunition_3 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_3.png';
import dropped_submunition_4 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_4.png';
import dropped_submunition_5 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_5.png';
import dropped_submunition_6 from '../assets/img/typesofbombs/Dropped Submunition/dropped_submunition_6.png';
import general_purpose_bomb_chemical_1 from '../assets/img/typesofbombs/General Purpose Bomb Chemical/general_purpose_bomb_chemical_1.png';
import general_purpose_bomb_chemical_2 from '../assets/img/typesofbombs/General Purpose Bomb Chemical/general_purpose_bomb_chemical_2.png';
import general_purpose_bomb_1 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_1.png';
import general_purpose_bomb_2 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_2.png';
import general_purpose_bomb_3 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_3.png';
import general_purpose_bomb_4 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_4.png';
import general_purpose_bomb_5 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_5.png';
import general_purpose_bomb_6 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_6.png';
import general_purpose_bomb_7 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_7.png';
import general_purpose_bomb_8 from '../assets/img/typesofbombs/Gerneral Purpose Bombs/general_purpose_bomb_8.png';
import missile_1 from '../assets/img/typesofbombs/Missiles/missile_1.png';
import missile_2 from '../assets/img/typesofbombs/Missiles/missile_2.png';
import mortar_1 from '../assets/img/typesofbombs/Mortars/mortar_1.png';
import mortar_2 from '../assets/img/typesofbombs/Mortars/mortar_2.png';
import mortar_3 from '../assets/img/typesofbombs/Mortars/mortar_3.png';
import mortar_4 from '../assets/img/typesofbombs/Mortars/mortar_4.png';
import projectile_1 from '../assets/img/typesofbombs/Projecticles/projectile_1.png';
import projectile_2 from '../assets/img/typesofbombs/Projecticles/projectile_2.png';
import projectile_3 from '../assets/img/typesofbombs/Projecticles/projectile_3.png';
import projectile_4 from '../assets/img/typesofbombs/Projecticles/projectile_4.png';
import projectile_5 from '../assets/img/typesofbombs/Projecticles/projectile_5.png';
import projectile_6 from '../assets/img/typesofbombs/Projecticles/projectile_6.png';
import projectile_7 from '../assets/img/typesofbombs/Projecticles/projectile_7.png';
import projectile_8 from '../assets/img/typesofbombs/Projecticles/projectile_8.png';
import projectile_9 from '../assets/img/typesofbombs/Projecticles/projectile_9.png';
import projectile_10 from '../assets/img/typesofbombs/Projecticles/projectile_10.png';
import projectile_11 from '../assets/img/typesofbombs/Projecticles/projectile_11.png';
import projectile_12 from '../assets/img/typesofbombs/Projecticles/projectile_12.png';
import projectile_13 from '../assets/img/typesofbombs/Projecticles/projectile_13.png';
import projectile_14 from '../assets/img/typesofbombs/Projecticles/projectile_14.png';
import projectile_15 from '../assets/img/typesofbombs/Projecticles/projectile_15.png';
import projectile_16 from '../assets/img/typesofbombs/Projecticles/projectile_16.png';
import rifle_grenade_1 from '../assets/img/typesofbombs/Rifle Grenades/rifle_grenade_1.png';
import rifle_grenade_2 from '../assets/img/typesofbombs/Rifle Grenades/rifle_grenade_2.png';
import rifle_grenade_3 from '../assets/img/typesofbombs/Rifle Grenades/rifle_grenade_3.png';
import rifle_grenade_4 from '../assets/img/typesofbombs/Rifle Grenades/rifle_grenade_4.png';
import rocket_1 from '../assets/img/typesofbombs/Rockets/rocket_1.png';
import rocket_2 from '../assets/img/typesofbombs/Rockets/rocket_2.png';
import grenade_1 from '../assets/img/typesofbombs/Thrown Grendades/grenade_1.png';
import grenade_2 from '../assets/img/typesofbombs/Thrown Grendades/grenade_2.png';
import grenade_3 from '../assets/img/typesofbombs/Thrown Grendades/grenade_3.png';
import grenade_4 from '../assets/img/typesofbombs/Thrown Grendades/grenade_4.png';
import grenade_5 from '../assets/img/typesofbombs/Thrown Grendades/grenade_5.png';
import grenade_6 from '../assets/img/typesofbombs/Thrown Grendades/grenade_6.png';
import grenade_7 from '../assets/img/typesofbombs/Thrown Grendades/grenade_7.png';
import grenade_8 from '../assets/img/typesofbombs/Thrown Grendades/grenade_8.png';
import grenade_9 from '../assets/img/typesofbombs/Thrown Grendades/grenade_9.png';
import grenade_10 from '../assets/img/typesofbombs/Thrown Grendades/grenade_10.png';

export const categories = [
  {
    id: 1,
    name: 'antiPersonnelMine',
    items: [
      {
        id: 1,
        name: 'antipersonnel_mine_1',
        picture: antipersonnel_mine_1,
      },
      {
        id: 2,
        name: 'antipersonnel_mine_2',
        picture: antipersonnel_mine_2,
      },
      {
        id: 3,
        name: 'antipersonnel_mine_3',
        picture: antipersonnel_mine_3,
      },
      {
        id: 4,
        name: 'antipersonnel_mine_4',
        picture: antipersonnel_mine_4,
      },
      {
        id: 5,
        name: 'antipersonnel_mine_5',
        picture: antipersonnel_mine_5,
      },
      {
        id: 6,
        name: 'antipersonnel_mine_6',
        picture: antipersonnel_mine_6,
      },
    ],
  },
  {
    id: 2,
    name: 'antiTankMine',
    items: [
      { id: 1, name: 'antitank_mine_1', picture: antitank_mine_1 },
      { id: 2, name: 'antitank_mine_2', picture: antitank_mine_2 },
      { id: 3, name: 'antitank_mine_3', picture: antitank_mine_3 },
      { id: 4, name: 'antitank_mine_4', picture: antitank_mine_4 },
    ],
  },
  {
    id: 3,

    name: 'droppedDispenser',
    items: [
      { id: 1, name: 'dropped_dispenser_1', picture: dropped_dispenser_1 },
      { id: 2, name: 'dropped_dispenser_2', picture: dropped_dispenser_2 },
      { id: 3, name: 'dropped_dispenser_4', picture: dropped_dispenser_4 },
      { id: 4, name: 'dropped_dispenser_5', picture: dropped_dispenser_5 },
      { id: 5, name: 'dropped_dispenser_6', picture: dropped_dispenser_6 },
      { id: 6, name: 'dropped_dispenser_7', picture: dropped_dispenser_7 },
    ],
  },
  {
    id: 4,

    name: 'droppedSubmunition',
    items: [
      { id: 1, name: 'dropped_submunition_1', picture: dropped_submunition_1 },
      { id: 2, name: 'dropped_submunition_2', picture: dropped_submunition_2 },
      { id: 3, name: 'dropped_submunition_3', picture: dropped_submunition_3 },
      { id: 4, name: 'dropped_submunition_4', picture: dropped_submunition_4 },
      { id: 5, name: 'dropped_submunition_5', picture: dropped_submunition_5 },
      { id: 6, name: 'dropped_submunition_6', picture: dropped_submunition_6 },
    ],
  },
  {
    id: 5,

    name: 'generalPurposeChemicalBomb',
    items: [
      {
        id: 1,
        name: 'general_purpose_bomb_chemical_1',
        picture: general_purpose_bomb_chemical_1,
      },
      {
        id: 2,
        name: 'general_purpose_bomb_chemical_2',
        picture: general_purpose_bomb_chemical_2,
      },
    ],
  },
  {
    id: 6,

    name: 'generalPurposeBomb',
    items: [
      {
        id: 1,
        name: 'general_purpose_bomb_1',
        picture: general_purpose_bomb_1,
      },
      {
        id: 2,
        name: 'general_purpose_bomb_2',
        picture: general_purpose_bomb_2,
      },
      {
        id: 3,
        name: 'general_purpose_bomb_3',
        picture: general_purpose_bomb_3,
      },
      {
        id: 4,
        name: 'general_purpose_bomb_4',
        picture: general_purpose_bomb_4,
      },
      {
        id: 5,
        name: 'general_purpose_bomb_5',
        picture: general_purpose_bomb_5,
      },
      {
        id: 6,
        name: 'general_purpose_bomb_6',
        picture: general_purpose_bomb_6,
      },
      {
        id: 7,
        name: 'general_purpose_bomb_7',
        picture: general_purpose_bomb_7,
      },
      {
        id: 8,
        name: 'general_purpose_bomb_8',
        picture: general_purpose_bomb_8,
      },
    ],
  },
  {
    id: 7,
    name: 'missile',
    items: [
      { id: 1, name: 'missile_1', picture: missile_1 },
      { id: 2, name: 'missile_2', picture: missile_2 },
    ],
  },
  {
    id: 8,

    name: 'mortar',
    items: [
      { id: 1, name: 'mortar_1', picture: mortar_1 },
      { id: 2, name: 'mortar_2', picture: mortar_2 },
      { id: 3, name: 'mortar_3', picture: mortar_3 },
      { id: 4, name: 'mortar_4', picture: mortar_4 },
    ],
  },
  {
    id: 9,
    name: 'projectile',
    items: [
      { id: 1, name: 'projectile_1', picture: projectile_1 },
      { id: 2, name: 'projectile_2', picture: projectile_2 },
      { id: 3, name: 'projectile_3', picture: projectile_3 },
      { id: 4, name: 'projectile_4', picture: projectile_4 },
      { id: 5, name: 'projectile_5', picture: projectile_5 },
      { id: 6, name: 'projectile_6', picture: projectile_6 },
      { id: 7, name: 'projectile_7', picture: projectile_7 },
      { id: 8, name: 'projectile_8', picture: projectile_8 },
      { id: 9, name: 'projectile_9', picture: projectile_9 },
      { id: 10, name: 'projectile_10', picture: projectile_10 },
      { id: 11, name: 'projectile_11', picture: projectile_11 },
      { id: 12, name: 'projectile_12', picture: projectile_12 },
      { id: 13, name: 'projectile_13', picture: projectile_13 },
      { id: 14, name: 'projectile_14', picture: projectile_14 },
      { id: 15, name: 'projectile_15', picture: projectile_15 },
      { id: 16, name: 'projectile_16', picture: projectile_16 },
    ],
  },
  {
    id: 10,

    name: 'rifleGrenade',
    items: [
      { id: 1, name: 'rifle_grenade_1', picture: rifle_grenade_1 },
      { id: 2, name: 'rifle_grenade_2', picture: rifle_grenade_2 },
      { id: 3, name: 'rifle_grenade_3', picture: rifle_grenade_3 },
      { id: 4, name: 'rifle_grenade_4', picture: rifle_grenade_4 },
    ],
  },
  {
    id: 11,

    name: 'rocket',
    items: [
      { id: 1, name: 'rocket_1', picture: rocket_1 },
      { id: 2, name: 'rocket_1', picture: rocket_2 },
    ],
  },
  {
    id: 12,
    name: 'grenade',
    items: [
      { id: 1, name: 'grenade_1', picture: grenade_1 },
      { id: 2, name: 'grenade_2', picture: grenade_2 },
      { id: 3, name: 'grenade_3', picture: grenade_3 },
      { id: 4, name: 'grenade_4', picture: grenade_4 },
      { id: 5, name: 'grenade_5', picture: grenade_5 },
      { id: 6, name: 'grenade_6', picture: grenade_6 },
      { id: 7, name: 'grenade_7', picture: grenade_7 },
      { id: 8, name: 'grenade_8', picture: grenade_8 },
      { id: 9, name: 'grenade_9', picture: grenade_9 },
      { id: 10, name: 'grenade_10', picture: grenade_10 },
    ],
  },
];
