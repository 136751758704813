import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Container } from 'layouts/Container/Container';
import { Routes, Route } from 'react-router-dom';
import ChooseReport from './ChooseReportsPage/ChooseReport';
import MakeReport from './MakeReportPage/MakeReport';
import { useSelector } from 'react-redux';

export const ReportPage = () => {
  const selectedEndpoint = useSelector(state => state.report);

  const smoothScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  useEffect(() => {
    document.title = 'Report | Sleuth Net';
    smoothScrollToTop();
  }, []);

  return (
    <Container component="main" className="ReportPageContainer">
      <Box alignItems={'normal'} marginTop={2}>
        <Routes>
          <Route path="/" element={<ChooseReport />} />
          {selectedEndpoint && (
            <Route
              path={`/${selectedEndpoint.id}`}
              element={<MakeReport />}
            />
          )}
        </Routes>
      </Box>
    </Container>
  );
};
