import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'; // Corrected import
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(LanguageDetector) // Corrected name
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    supportedLngs: ['en', 'ua'], // Updated
    nonExplicitSupportedLngs: true, // Added for language variants
    debug: true, // Set to false in production
    detection: {
      order: ['localStorage', 'cookie', 'navigator'], // Added 'navigator'
      caches: ['localStorage', 'cookie'],
    },
    ns: ['ui', 'auth', 'reports', 'hazards', 'content', 'countries', 'translation'],
    defaultNS: 'ui',
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;
