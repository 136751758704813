import { createSlice } from '@reduxjs/toolkit';

const nearbyReportsSlice = createSlice({
  name: 'nearbyReports',
  initialState: {
    nearbyReports: [], // Array of reports
  },
  reducers: {
    setNearbyReports: (state, action) => {
      return {
        ...state,
        nearbyReports: action.payload
      };
    },
    
    clearNearbyReports: state => {
      state.nearbyReports = []; 
    },
  },
});

export const selectNearbyReports = state => state.nearbyReports; 
export const { setNearbyReports, clearNearbyReports } =
  nearbyReportsSlice.actions;
export default nearbyReportsSlice.reducer;