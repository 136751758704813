/// What is this page?

import { useState, useEffect } from 'react';
import { endpoints } from 'utils/constants';
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';
import { selectUser } from 'redux/auth/authSlice';
import { useSelector } from 'react-redux';
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//import { getCountryCode   } from 'countries-list'
import './UserReports.styled.css';

export const UserReports = () => {
  const { t } = useTranslation();
  const [endpointData, setEndpointData] = useState({});
  const db = getFirestore();
  const id = useSelector(selectUser);
  const push = useNavigate();
  const dispatch = useDispatch();
  const [displayedReports, setDisplayedReports] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [displayedRows, setDisplayedRows] = useState(10);

  useEffect(() => {
    endpoints.forEach(({ name }) => {
      if (!endpointData[name]) {
        const reportsRef = collection(db, name);
        const reportsQuery = query(reportsRef);

        getDocs(reportsQuery).then(querySnapshot => {
          const data = [];
          querySnapshot.forEach(doc => {
            data.push({ id: doc.id, ...doc.data() });
          });
          setEndpointData(prevData => ({
            ...prevData,
            [name]: Object.entries(data || {}).map(([id, value]) => ({
              id,
              ...value,
            })),
          }));
        });
      }
    });
  }, [db, endpointData]);

  useEffect(() => {
    if (Object.keys(endpointData).length === 0) {
      return;
    }

    const myReports = endpoints.reduce((acc, { name }) => {
      const data = endpointData[name];
      if (data && data.length > 0) {
        acc.push(
          ...data.filter(
            report =>
              report.originalReportUserId === id && report.archived === 0
          )
        );
      }
      return acc;
    }, []);

    setDisplayedReports(myReports);
  }, [endpointData, id]);

  const handleUpdateReportClick = report => {
    dispatch(setSelectedReport(report));
    push('/edit-report');
  };

  return (
    <Box className="MainUserReportBox">
      <Typography variant="h2" whiteSpace={'nowrap'}>
        {t('ui:report.reportSubmissions')}
      </Typography>
      <Typography variant="body2" mb={2}>
        {t('ui:report.statusMessage')}
      </Typography>
      <Table>
        <TableBody>
          {displayedReports.slice(0, displayedRows).map((report, index) => (
            <TableRow
              key={report.id}
              sx={{
                backgroundColor:
                  index % 2 === 0
                    ? 'rgba(45, 45, 45, 0.9) !important'
                    : 'rgba(45, 45, 45, 0.3) !important',
              }}
            >
              <TableCell sx={{ border: 'none' }}>
                <Button
                  variant="outlined"
                  onClick={() => handleUpdateReportClick(report)}
                  children={t('ui:button.review')}
                />
              </TableCell>
              <TableCell sx={{ border: 'none' }}>
                <Typography>{t(`hazards:hazardName.${report.reportType}`)}</Typography>
              </TableCell>
              <TableCell sx={{ border: 'none' }}>
                {report.country ? (
                  <Typography>{report.country}</Typography>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell sx={{ border: 'none' }}>
                <Typography>
                  {`${new Date(
                    report.timeObserved.seconds * 1000
                  ).toLocaleDateString()}`}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};
