import {
  Box,
  Typography,
  Modal,
  IconButton,
  useMediaQuery,
  Button,
  Grid,
  CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { Container } from 'layouts/Container/Container';
import UxoReportForm from 'components/form/ReportForm/UxoReportForm';
import MilitaryReportForm from 'components/form/ReportForm/MilitaryReportForm';
import ReportForm from 'components/form/ReportForm/ReportForm';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getUserLocation } from 'services/getUserLocation';
import Geocode from 'react-geocode';
import CloseIcon from '@mui/icons-material/Close';
import './Makereport.styled.css';
import 'assets/styles/Theme';
import SubMapComponent from 'components/common/Map/SubMapComponent';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API_KEY);

const MakeReport = () => {
  const { t } = useTranslation();
  const selectedEndpoint = useSelector(state => state.report);
  console.log('selectedEndpoint:', selectedEndpoint);
  console.log('selectedEndpoint.name', selectedEndpoint.name);
  console.log('something is happening...');
  const navigate = useNavigate();
  const isScreenLessThan900px = useMediaQuery('(max-width:899px)');
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [selectedMapLocation, setSelectedMapLocation] = useState(null);
  const [selectedLatLng, setSelectedLatLng] = useState({});
  const [userLocation, setUserLocation] = useState(() => {
    const storedLocation = localStorage.getItem('userLocation');
    return storedLocation ? JSON.parse(storedLocation) : null;
  });
  const [locationInfo, setLocationInfo] = useState({
    city: '',
    state: '',
    country: '',
  });
  const [isMapLoading, setIsMapLoading] = useState(false);

  console.log(selectedEndpoint);

  const handleMapClick = async latLng => {
    setSelectedMapLocation(latLng);
    setSelectedLatLng({ lat: latLng.lat, lng: latLng.lng });

    const { lat, lng } = latLng;
    const locationData = await getLocationInfo(lat, lng);
    setLocationInfo(locationData);
  };

  useEffect(() => {
    async function fetchUserLocation() {
      let storedLocation = localStorage.getItem('userLocation');
      if (storedLocation) {
        setUserLocation(JSON.parse(storedLocation));
      } else {
        const location = await getUserLocation();
        if (location) {
          setUserLocation(location);
          localStorage.setItem('userLocation', JSON.stringify(location));
        } else {
          // Обробка помилки
        }
      }
    }

    fetchUserLocation();
  }, []);

  const getLocationInfo = async (lat, lng) => {
    try {
      const response = await Geocode.fromLatLng(lat, lng);
      const addressComponents = response.results[0].address_components;
      let city, state, country;

      addressComponents.forEach(component => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.long_name;
        } else if (component.types.includes('country')) {
          country = component.long_name;
        }
      });

      return {
        city: city || '',
        state: state || '',
        country: country || '',
      };
    } catch (error) {
      console.error('Error fetching location info:', error);
      return {
        city: '',
        state: '',
        country: '',
      };
    }
  };

  useEffect(() => {
    if (
      selectedMapLocation &&
      typeof selectedMapLocation === 'object' &&
      typeof selectedMapLocation.toJSON === 'function'
    ) {
      const { lat, lng } = selectedMapLocation.toJSON();
      getLocationInfo(lat, lng).then(locationInfo => {
        setLocationInfo(locationInfo);
      });
    }
  }, [selectedMapLocation]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const openMapModal = () => {
    setMapModalOpen(true);
  };

  const closeMapModal = () => {
    setMapModalOpen(false);
  };

  const handleMapLoad = () => {
    console.log('Map has been loaded');
    setIsMapLoading(false);
  };

  console.log(isMapLoading);
  return (
    <Container
      component="main"
      justifyContent="center"
      alignItems="center"
      display="flex"
    >
      <Button
        variant="outlined"
        children={t('ui:button.goBack')}
        sx={{ marginLeft: 0, marginBottom: 2 }}
        onClick={handleGoBack}
      />

      <Grid container>
        <Grid item xs={12} md={7}>
          <Box className="MakeReportPageBox">
            <Typography variant="overline" textTransform="uppercase">
              {t('reports:reportForm.createTitle')}
            </Typography>

            {selectedEndpoint.id === 'uxo' && (
              <UxoReportForm
                id={selectedEndpoint.id}
                openMapModal={openMapModal}
                selectedMapLocation={selectedMapLocation}
                selectedLatLng={selectedLatLng}
                setSelectedMapLocation={setSelectedMapLocation}
                onCoordinatesChange={coordinates =>
                  setSelectedMapLocation(coordinates)
                }
                setSelectedLatLng={setSelectedLatLng}
                locationInfo={locationInfo}
                userLocation={userLocation}
              />
            )}
            {selectedEndpoint.id === 'militaryActivity' && (
              <MilitaryReportForm
                id={selectedEndpoint.id}
                openMapModal={openMapModal}
                selectedMapLocation={selectedMapLocation}
                selectedLatLng={selectedLatLng}
                setSelectedMapLocation={setSelectedMapLocation}
                onCoordinatesChange={coordinates =>
                  setSelectedMapLocation(coordinates)
                }
                setSelectedLatLng={setSelectedLatLng}
                locationInfo={locationInfo}
                userLocation={userLocation}
              />
            )}
            {selectedEndpoint.id !== 'uxo' &&
              selectedEndpoint.id !== 'militaryActivity' && (
                <ReportForm
                  endpoint={selectedEndpoint.id}
                  id={selectedEndpoint.id}
                  openMapModal={openMapModal}
                  selectedMapLocation={selectedMapLocation}
                  selectedLatLng={selectedLatLng}
                  setSelectedMapLocation={setSelectedMapLocation}
                  onCoordinatesChange={coordinates =>
                    setSelectedMapLocation(coordinates)
                  }
                  setSelectedLatLng={setSelectedLatLng}
                  locationInfo={locationInfo}
                  userLocation={userLocation}
                />
              )}
          </Box>
        </Grid>

        <Grid item xs={12} md={5}>
          {!isScreenLessThan900px && (
            <Box
              sx={{ flex: 1, height: 400, marginLeft: 3, bgcolor: '#1d1d1d' }}
            >
              {isMapLoading ? (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                  width="100%"
                >
                  <CircularProgress />
                </Box>
              ) : (
                <SubMapComponent
                  defaultCenter={selectedMapLocation || userLocation}
                  position={selectedMapLocation}
                  onLoad={handleMapLoad}
                  defaultZoom={10}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>

      <Modal
        open={mapModalOpen}
        onClose={closeMapModal}
        aria-labelledby="map-modal-title"
      >
        <Box className="ReportPageMapModal">
          <IconButton
            aria-label="close"
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: '-5%',
              left: '100%',
              bgcolor: 'background.primeGrey',
            }}
            onClick={closeMapModal}
          >
            <CloseIcon />
          </IconButton>
          <SubMapComponent
            defaultCenter={selectedMapLocation || userLocation}
            defaultZoom={15}
            position={selectedMapLocation}
            onClick={handleMapClick}
            onLoad={handleMapLoad}
          />
          {/* <SearchBox onSelectLocation={handleSelectLocation} /> */}
        </Box>
      </Modal>
    </Container>
  );
};

export default MakeReport;
