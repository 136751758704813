import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Button, Stack, useMediaQuery } from '@mui/material';
import json2mq from 'json2mq';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';

export const SignUpButtons = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery(
    json2mq({
      minWidth: 500,
    })
  );

  return (
    <Box flexDirection={`${matches ? 'row' : 'column'}`} p={2}>
      <Stack direction="row" spacing={2}>
      <Link to="/sign-in/register">
        <Button
          variant='outlined'
            children={t('ui:button.register')}
          />
        </Link>
        
        <Link to="/sign-in/login">
          <Button
          variant='contained'
            children={t('ui:button.login')}
          />
        </Link>
      </Stack>
    </Box>
  );
};
