export const emailRegExp =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const phoneRegExp =
  /^[+]?[\d]{1,3}?[-.\s]?[\d]{1,4}?[-.\s]?[\d]{1,4}?[-.\s]?[\d]{1,9}$/;

export const passwordRegExp =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s)(?!.*\W).*$/;

export const breakPoints = {
  PHONE: 320,
  TABLET: 834,
  DESKTOP: 1280,
  UHD: 1440,
};

// export const endpoints = [
//   {
//     id: 'uxo',
//     name: 'UXO',
//     description: 'Unexploded bombs, munitions, or explosives',
//   },
//   {
//     id: 'militaryActivity',
//     name: 'Military Activity',
//     description: 'Military movements',
//   },
//   {
//     id: 'wildlife',
//     name: 'Wildlife',
//     description: 'Dangerous wild animals',
//   },
//   {
//     id: 'warCrime',
//     name: 'War Crime',
//     description: 'Crimes committed by military personnel',
//   },
//   {
//     id: 'criminalActivity',
//     name: 'Criminal Activity',
//     description: 'Civil violence or crime',
//   },
//   {
//     id: 'resourceScarcity',
//     name: 'Resource Scarcity',
//     description: 'Low supply, descrution, or absence of a resource',
//   },
//   {
//     id: 'structureHazard',
//     name: 'Structure Hazard',
//     description: 'Building or structure hazards',
//   },
//   {
//     id: 'naturalDisaster',
//     name: 'Natural Disaster',
//     description: 'Weather events that cause damage',
//   },
//   {
//     id: 'terroristActivity',
//     name: 'Terrorist Activity',
//     description: 'Terrorist-related activity',
//   },
//   {
//     id: "environmentalHazard",
//     name: "Envrionment Hazard",
//     description: "Accidents or incidents involving transportation.",
//   },
//   {
//     id: 'nasa',
//     name: 'NASA EONet Reports',
//     description: 'Autogenerated reports from NASA',
//   },
// ];
export const endpoints = [
  {
    id: 'uxo',
    name: 'UXO',
    description: 'Unexploded bombs, munitions, or explosives',
  },
  {
    id: 'militaryActivity',
    name: 'Military Activity',
    description: 'Military movements',
  },
  {
    id: 'wildlife',
    name: 'Wildlife',
    description: 'Dangerous wild animals',
  },
  {
    id: 'warCrime',
    name: 'War Crime',
    description: 'Crimes committed by military personnel',
  },
  {
    id: 'criminalActivity',
    name: 'Criminal Activity',
    description: 'Civil violence or crime',
  },
  {
    id: 'resourceScarcity',
    name: 'Resource Scarcity',
    description: 'Low supply, descrution, or absence of a resource',
  },
  {
    id: 'structureHazard',
    name: 'Structure Hazard',
    description: 'Building or structure hazards',
  },
  {
    id: 'naturalDisaster',
    name: 'Natural Disaster',
    description: 'Weather events that cause damage',
  },
  {
    id: 'terroristActivity',
    name: 'Terrorist Activity',
    description: 'Terrorist-related activity',
  },
  {
    id: "environmentalHazard",
    name: "Envrionment Hazard",
    description: "Accidents or incidents involving transportation.",
  },
  {
    id: 'transportationHazard',
    name: 'Transportation Hazard',
    description: 'Accidents or incidents involving transportation.',
  },
  {
    id: 'cyberThreat',
    name: 'Cyber Threat',
    description: 'Threats originating from digital activities.',
  },
  {
    id: 'publicHealthHazard',
    name: 'Public Health Hazard',
    description: 'Threats to the health of the population.',
  },
  {
    id: 'humanTrafficking',
    name: 'Human Trafficking',
    description: 'Trade of humans for exploitation or commercial gain.',
  },
  {
    id: 'nasa',
    name: 'NASA EONet Reports',
    description: 'Autogenerated reports from NASA',
  },
];


export const loadGoogleMapsScript = (apiKey, callback) => {
  const existingScript = document.getElementById('googleMaps');

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) callback();
    };
  } else {
    if (callback) callback();
  }
};

export const center_ua = {
  lat: 49.4037,
  lng: 31.40257,
};

export const typeOccupation = [
  {
    id: 'cyber_threat_analysis',
    name: 'Cyber Threat Analysis',
    category: 'Cybersecurity',
  },
  {
    id: 'cybersecurity_operations',
    name: 'Cybersecurity Operations',
    category: 'Cybersecurity',
  },
  {
    id: 'network_security_architecture',
    name: 'Network Security Architecture',
    category: 'Cybersecurity',
  },
  {
    id: 'digital_forensics',
    name: 'Digital Forensics',
    category: 'Cybersecurity',
  },
  {
    id: 'border_security_operations',
    name: 'Border Security Operations',
    category: 'Defense and Security',
  },
  {
    id: 'explosive_ordnance_disposal',
    name: 'Explosive Ordnance Disposal (EOD)',
    category: 'Defense and Security',
  },
  {
    id: 'military_strategy_tactics',
    name: 'Military Strategy and Tactics',
    category: 'Defense and Security',
  },
  {
    id: 'security_intelligence_planning',
    name: 'Security Intelligence Planning',
    category: 'Defense and Security',
  },
  {
    id: 'surveillance_reconnaissance',
    name: 'Surveillance and Reconnaissance',
    category: 'Defense and Security',
  },
  {
    id: 'tactical_training_operations',
    name: 'Tactical Training and Operations',
    category: 'Defense and Security',
  },
  {
    id: 'anti_terrorism_counterterrorism',
    name: 'Anti-Terrorism and Counterterrorism',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'counterintelligence',
    name: 'Counterintelligence',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'cyber_intelligence_analysis',
    name: 'Cyber Intelligence Analysis',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'human_intelligence_humint',
    name: 'Human Intelligence (HUMINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'intelligence_analysis',
    name: 'Intelligence Analysis',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'open_source_intelligence_osint',
    name: 'Open Source Intelligence (OSINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'signals_intelligence_sigint',
    name: 'Signals Intelligence (SIGINT)',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'threat_assessment',
    name: 'Threat Assessment',
    category: 'Intelligence and Counterintelligence',
  },
  {
    id: 'combat_medic_field_medical_services',
    name: 'Combat Medic and Field Medical Services',
    category: 'Medical and Emergency',
  },
  {
    id: 'emergency_response_coordination',
    name: 'Emergency Response Coordination',
    category: 'Medical and Emergency',
  },
  {
    id: 'disaster_response_planning',
    name: 'Disaster Response Planning',
    category: 'Medical and Emergency',
  },
  {
    id: 'medical_intelligence_analysis',
    name: 'Medical Intelligence Analysis',
    category: 'Medical and Emergency',
  },
  {
    id: 'criminal_intelligence',
    name: 'Criminal Intelligence',
    category: 'Law Enforcement',
  },
  {
    id: 'forensic_science',
    name: 'Forensic Science',
    category: 'Law Enforcement',
  },
  {
    id: 'law_enforcement_investigations',
    name: 'Law Enforcement Investigations',
    category: 'Law Enforcement',
  },
  {
    id: 'narcotics_enforcement',
    name: 'Narcotics Enforcement',
    category: 'Law Enforcement',
  },
  {
    id: 'crime_scene_investigation',
    name: 'Crime Scene Investigation',
    category: 'Law Enforcement',
  },
  {
    id: 'special_operations_forces_sof',
    name: 'Special Operations Forces (SOF)',
    category: 'Military',
  },
  {
    id: 'weapons_ammunition_handling',
    name: 'Weapons and Ammunition Handling',
    category: 'Military',
  },
  {
    id: 'military_logistics_support',
    name: 'Military Logistics Support',
    category: 'Military',
  },
  {
    id: 'armored_vehicle_operations',
    name: 'Armored Vehicle Operations',
    category: 'Military',
  },
  {
    id: 'homeland_security',
    name: 'Homeland Security',
    category: 'Security and Protective Services',
  },
  {
    id: 'physical_security_consulting',
    name: 'Physical Security Consulting',
    category: 'Security and Protective Services',
  },
  {
    id: 'protective_services_executive_protection',
    name: 'Protective Services and Executive Protection',
    category: 'Security and Protective Services',
  },
  {
    id: 'risk_assessment_mitigation',
    name: 'Risk Assessment and Mitigation',
    category: 'Security and Protective Services',
  },
  {
    id: 'security_systems_integration',
    name: 'Security Systems Integration',
    category: 'Security and Protective Services',
  },
  {
    id: 'airport_security_management',
    name: 'Airport Security Management',
    category: 'Security and Protective Services',
  },
  {
    id: 'counter_surveillance_techniques',
    name: 'Counter-Surveillance Techniques',
    category: 'Security and Protective Services',
  },
];



export const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png'];
export const FILE_SIZE = 10000000; // 10 MB

export const MAX_VIDEO_SIZE = 1024 * 1024 * 100; // 100 MB
export const SUPPORTED_VIDEO_FORMATS = ['video/mp4', 'video/avi', 'video/mkv'];

export const currentDateTime = () => {
  const now = new Date();
  return now.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
};

export const reportDateTime = prop => {
  const date = new Date(prop);
  return date.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
};


export const countries = {
  "AN": {
    "name": "Antarctica",
    "isoCode": "AN",
    "continent": "Antarctica"
  },
  "AF": {
    "name": "Afghanistan",
    "isoCode": "AF",
    "continent": "Asia"
  },
  "AL": {
    "name": "Albania",
    "isoCode": "AL",
    "continent": "Europe"
  },
  "DZ": {
    "name": "Algeria",
    "isoCode": "DZ",
    "continent": "Africa"
  },
  "AD": {
    "name": "Andorra",
    "isoCode": "AD",
    "continent": "Europe"
  },
  "AO": {
    "name": "Angola",
    "isoCode": "AO",
    "continent": "Africa"
  },
  "AG": {
    "name": "Antigua and Barbuda",
    "isoCode": "AG",
    "continent": "North America"
  },
  "AR": {
    "name": "Argentina",
    "isoCode": "AR",
    "continent": "South America"
  },
  "AM": {
    "name": "Armenia",
    "isoCode": "AM",
    "continent": "Asia"
  },
  "AU": {
    "name": "Australia",
    "isoCode": "AU",
    "continent": "Oceania"
  },
  "AT": {
    "name": "Austria",
    "isoCode": "AT",
    "continent": "Europe"
  },
  "AZ": {
    "name": "Azerbaijan",
    "isoCode": "AZ",
    "continent": "Asia"
  },
  "BS": {
    "name": "Bahamas",
    "isoCode": "BS",
    "continent": "North America"
  },
  "BH": {
    "name": "Bahrain",
    "isoCode": "BH",
    "continent": "Asia"
  },
  "BD": {
    "name": "Bangladesh",
    "isoCode": "BD",
    "continent": "Asia"
  },
  "BB": {
    "name": "Barbados",
    "isoCode": "BB",
    "continent": "North America"
  },
  "BY": {
    "name": "Belarus",
    "isoCode": "BY",
    "continent": "Europe"
  },
  "BE": {
    "name": "Belgium",
    "isoCode": "BE",
    "continent": "Europe"
  },
  "BZ": {
    "name": "Belize",
    "isoCode": "BZ",
    "continent": "North America"
  },
  "BJ": {
    "name": "Benin",
    "isoCode": "BJ",
    "continent": "Africa"
  },
  "BT": {
    "name": "Bhutan",
    "isoCode": "BT",
    "continent": "Asia"
  },
  "BO": {
    "name": "Bolivia",
    "isoCode": "BO",
    "continent": "South America"
  },
  "BA": {
    "name": "Bosnia and Herzegovina",
    "isoCode": "BA",
    "continent": "Europe"
  },
  "BW": {
    "name": "Botswana",
    "isoCode": "BW",
    "continent": "Africa"
  },
  "BR": {
    "name": "Brazil",
    "isoCode": "BR",
    "continent": "South America"
  },
  "BN": {
    "name": "Brunei",
    "isoCode": "BN",
    "continent": "Asia"
  },
  "BG": {
    "name": "Bulgaria",
    "isoCode": "BG",
    "continent": "Europe"
  },
  "BF": {
    "name": "Burkina Faso",
    "isoCode": "BF",
    "continent": "Africa"
  },
  "BI": {
    "name": "Burundi",
    "isoCode": "BI",
    "continent": "Africa"
  },
  "CV": {
    "name": "Cabo Verde",
    "isoCode": "CV",
    "continent": "Africa"
  },
  "KH": {
    "name": "Cambodia",
    "isoCode": "KH",
    "continent": "Asia"
  },
  "CM": {
    "name": "Cameroon",
    "isoCode": "CM",
    "continent": "Africa"
  },
  "CA": {
    "name": "Canada",
    "isoCode": "CA",
    "continent": "North America"
  },
  "CF": {
    "name": "Central African Republic",
    "isoCode": "CF",
    "continent": "Africa"
  },
  "TD": {
    "name": "Chad",
    "isoCode": "TD",
    "continent": "Africa"
  },
  "CL": {
    "name": "Chile",
    "isoCode": "CL",
    "continent": "South America"
  },
  "CN": {
    "name": "China",
    "isoCode": "CN",
    "continent": "Asia"
  },
  "CO": {
    "name": "Colombia",
    "isoCode": "CO",
    "continent": "South America"
  },
  "KM": {
    "name": "Comoros",
    "isoCode": "KM",
    "continent": "Africa"
  },
  "CD": {
    "name": "Congo, Democratic Republic of the",
    "isoCode": "CD",
    "continent": "Africa"
  },
  "CG": {
    "name": "Congo, Republic of the",
    "isoCode": "CG",
    "continent": "Africa"
  },
  "CR": {
    "name": "Costa Rica",
    "isoCode": "CR",
    "continent": "North America"
  },
  "CI": {
    "name": "Cote d'Ivoire",
    "isoCode": "CI",
    "continent": "Africa"
  },
  "HR": {
    "name": "Croatia",
    "isoCode": "HR",
    "continent": "Europe"
  },
  "CU": {
    "name": "Cuba",
    "isoCode": "CU",
    "continent": "North America"
  },
  "CY": {
    "name": "Cyprus",
    "isoCode": "CY",
    "continent": "Europe"
  },
  "CZ": {
    "name": "Czech Republic",
    "isoCode": "CZ",
    "continent": "Europe"
  },
  "DK": {
    "name": "Denmark",
    "isoCode": "DK",
    "continent": "Europe"
  },
  "DJ": {
    "name": "Djibouti",
    "isoCode": "DJ",
    "continent": "Africa"
  },
  "DM": {
    "name": "Dominica",
    "isoCode": "DM",
    "continent": "North America"
  },
  "DO": {
    "name": "Dominican Republic",
    "isoCode": "DO",
    "continent": "North America"
  },
  "TL": {
    "name": "East Timor (Timor-Leste)",
    "isoCode": "TL",
    "continent": "Asia"
  },
  "EC": {
    "name": "Ecuador",
    "isoCode": "EC",
    "continent": "South America"
  },
  "EG": {
    "name": "Egypt",
    "isoCode": "EG",
    "continent": "Africa"
  },
  "SV": {
    "name": "El Salvador",
    "isoCode": "SV",
    "continent": "North America"
  },
  "GQ": {
    "name": "Equatorial Guinea",
    "isoCode": "GQ",
    "continent": "Africa"
  },
  "ER": {
    "name": "Eritrea",
    "isoCode": "ER",
    "continent": "Africa"
  },
  "EE": {
    "name": "Estonia",
    "isoCode": "EE",
    "continent": "Europe"
  },
  "SZ": {
    "name": "Eswatini",
    "isoCode": "SZ",
    "continent": "Africa"
  },
  "ET": {
    "name": "Ethiopia",
    "isoCode": "ET",
    "continent": "Africa"
  },
  "FJ": {
    "name": "Fiji",
    "isoCode": "FJ",
    "continent": "Oceania"
  },
  "FI": {
    "name": "Finland",
    "isoCode": "FI",
    "continent": "Europe"
  },
  "FR": {
    "name": "France",
    "isoCode": "FR",
    "continent": "Europe"
  },
  "GA": {
    "name": "Gabon",
    "isoCode": "GA",
    "continent": "Africa"
  },
  "GM": {
    "name": "Gambia",
    "isoCode": "GM",
    "continent": "Africa"
  },
  "GE": {
    "name": "Georgia",
    "isoCode": "GE",
    "continent": "Asia"
  },
  "DE": {
    "name": "Germany",
    "isoCode": "DE",
    "continent": "Europe"
  },
  "GH": {
    "name": "Ghana",
    "isoCode": "GH",
    "continent": "Africa"
  },
  "GR": {
    "name": "Greece",
    "isoCode": "GR",
    "continent": "Europe"
  },
  "GD": {
    "name": "Grenada",
    "isoCode": "GD",
    "continent": "North America"
  },
  "GT": {
    "name": "Guatemala",
    "isoCode": "GT",
    "continent": "North America"
  },
  "GN": {
    "name": "Guinea",
    "isoCode": "GN",
    "continent": "Africa"
  },
  "GW": {
    "name": "Guinea-Bissau",
    "isoCode": "GW",
    "continent": "Africa"
  },
  "GY": {
    "name": "Guyana",
    "isoCode": "GY",
    "continent": "South America"
  },
  "HT": {
    "name": "Haiti",
    "isoCode": "HT",
    "continent": "North America"
  },
  "HN": {
    "name": "Honduras",
    "isoCode": "HN",
    "continent": "North America"
  },
  "HU": {
    "name": "Hungary",
    "isoCode": "HU",
    "continent": "Europe"
  },
  "IS": {
    "name": "Iceland",
    "isoCode": "IS",
    "continent": "Europe"
  },
  "IN": {
    "name": "India",
    "isoCode": "IN",
    "continent": "Asia"
  },
  "ID": {
    "name": "Indonesia",
    "isoCode": "ID",
    "continent": "Asia"
  },
  "IR": {
    "name": "Iran",
    "isoCode": "IR",
    "continent": "Asia"
  },
  "IQ": {
    "name": "Iraq",
    "isoCode": "IQ",
    "continent": "Asia"
  },
  "IE": {
    "name": "Ireland",
    "isoCode": "IE",
    "continent": "Europe"
  },
  "IL": {
    "name": "Israel",
    "isoCode": "IL",
    "continent": "Asia"
  },
  "IT": {
    "name": "Italy",
    "isoCode": "IT",
    "continent": "Europe"
  },
  "JM": {
    "name": "Jamaica",
    "isoCode": "JM",
    "continent": "North America"
  },
  "JP": {
    "name": "Japan",
    "isoCode": "JP",
    "continent": "Asia"
  },
  "JO": {
    "name": "Jordan",
    "isoCode": "JO",
    "continent": "Asia"
  },
  "KZ": {
    "name": "Kazakhstan",
    "isoCode": "KZ",
    "continent": "Asia"
  },
  "KE": {
    "name": "Kenya",
    "isoCode": "KE",
    "continent": "Africa"
  },
  "KI": {
    "name": "Kiribati",
    "isoCode": "KI",
    "continent": "Oceania"
  },
  "KP": {
    "name": "Korea, North",
    "isoCode": "KP",
    "continent": "Asia"
  },
  "KR": {
    "name": "Korea, South",
    "isoCode": "KR",
    "continent": "Asia"
  },
  "XK": {
    "name": "Kosovo",
    "isoCode": "XK",
    "continent": "Europe"
  },
  "KW": {
    "name": "Kuwait",
    "isoCode": "KW",
    "continent": "Asia"
  },
  "KG": {
    "name": "Kyrgyzstan",
    "isoCode": "KG",
    "continent": "Asia"
  },
  "LA": {
    "name": "Laos",
    "isoCode": "LA",
    "continent": "Asia"
  },
  "LV": {
    "name": "Latvia",
    "isoCode": "LV",
    "continent": "Europe"
  },
  "LB": {
    "name": "Lebanon",
    "isoCode": "LB",
    "continent": "Asia"
  },
  "LS": {
    "name": "Lesotho",
    "isoCode": "LS",
    "continent": "Africa"
  },
  "LR": {
    "name": "Liberia",
    "isoCode": "LR",
    "continent": "Africa"
  },
  "LY": {
    "name": "Libya",
    "isoCode": "LY",
    "continent": "Africa"
  },
  "LI": {
    "name": "Liechtenstein",
    "isoCode": "LI",
    "continent": "Europe"
  },
  "LT": {
    "name": "Lithuania",
    "isoCode": "LT",
    "continent": "Europe"
  },
  "LU": {
    "name": "Luxembourg",
    "isoCode": "LU",
    "continent": "Europe"
  },
  "MG": {
    "name": "Madagascar",
    "isoCode": "MG",
    "continent": "Africa"
  },
  "MW": {
    "name": "Malawi",
    "isoCode": "MW",
    "continent": "Africa"
  },
  "MY": {
    "name": "Malaysia",
    "isoCode": "MY",
    "continent": "Asia"
  },
  "MV": {
    "name": "Maldives",
    "isoCode": "MV",
    "continent": "Asia"
  },
  "ML": {
    "name": "Mali",
    "isoCode": "ML",
    "continent": "Africa"
  },
  "MT": {
    "name": "Malta",
    "isoCode": "MT",
    "continent": "Europe"
  },
  "MH": {
    "name": "Marshall Islands",
    "isoCode": "MH",
    "continent": "Oceania"
  },
  "MR": {
    "name": "Mauritania",
    "isoCode": "MR",
    "continent": "Africa"
  },
  "MU": {
    "name": "Mauritius",
    "isoCode": "MU",
    "continent": "Africa"
  },
  "MX": {
    "name": "Mexico",
    "isoCode": "MX",
    "continent": "North America"
  },
  "FM": {
    "name": "Micronesia, Federated States of",
    "isoCode": "FM",
    "continent": "Oceania"
  },
  "MD": {
    "name": "Moldova",
    "isoCode": "MD",
    "continent": "Europe"
  },
  "MC": {
    "name": "Monaco",
    "isoCode": "MC",
    "continent": "Europe"
  },
  "MN": {
    "name": "Mongolia",
    "isoCode": "MN",
    "continent": "Asia"
  },
  "ME": {
    "name": "Montenegro",
    "isoCode": "ME",
    "continent": "Europe"
  },
  "MA": {
    "name": "Morocco",
    "isoCode": "MA",
    "continent": "Africa"
  },
  "MZ": {
    "name": "Mozambique",
    "isoCode": "MZ",
    "continent": "Africa"
  },
  "MM": {
    "name": "Myanmar",
    "isoCode": "MM",
    "continent": "Asia"
  },
  "NA": {
    "name": "Namibia",
    "isoCode": "NA",
    "continent": "Africa"
  },
  "NR": {
    "name": "Nauru",
    "isoCode": "NR",
    "continent": "Oceania"
  },
  "NP": {
    "name": "Nepal",
    "isoCode": "NP",
    "continent": "Asia"
  },
  "NL": {
    "name": "Netherlands",
    "isoCode": "NL",
    "continent": "Europe"
  },
  "NZ": {
    "name": "New Zealand",
    "isoCode": "NZ",
    "continent": "Oceania"
  },
  "NI": {
    "name": "Nicaragua",
    "isoCode": "NI",
    "continent": "North America"
  },
  "NE": {
    "name": "Niger",
    "isoCode": "NE",
    "continent": "Africa"
  },
  "NG": {
    "name": "Nigeria",
    "isoCode": "NG",
    "continent": "Africa"
  },
  "MK": {
    "name": "North Macedonia",
    "isoCode": "MK",
    "continent": "Europe"
  },
  "NO": {
    "name": "Norway",
    "isoCode": "NO",
    "continent": "Europe"
  },
  "OM": {
    "name": "Oman",
    "isoCode": "OM",
    "continent": "Asia"
  },
  "PK": {
    "name": "Pakistan",
    "isoCode": "PK",
    "continent": "Asia"
  },
  "PW": {
    "name": "Palau",
    "isoCode": "PW",
    "continent": "Oceania"
  },
  "PS": {
    "name": "Palestine",
    "isoCode": "PS",
    "continent": "Asia"
  },
  "PA": {
    "name": "Panama",
    "isoCode": "PA",
    "continent": "North America"
  },
  "PG": {
    "name": "Papua New Guinea",
    "isoCode": "PG",
    "continent": "Oceania"
  },
  "PY": {
    "name": "Paraguay",
    "isoCode": "PY",
    "continent": "South America"
  },
  "PE": {
    "name": "Peru",
    "isoCode": "PE",
    "continent": "South America"
  },
  "PH": {
    "name": "Philippines",
    "isoCode": "PH",
    "continent": "Asia"
  },
  "PL": {
    "name": "Poland",
    "isoCode": "PL",
    "continent": "Europe"
  },
  "PT": {
    "name": "Portugal",
    "isoCode": "PT",
    "continent": "Europe"
  },
  "QA": {
    "name": "Qatar",
    "isoCode": "QA",
    "continent": "Asia"
  },
  "RO": {
    "name": "Romania",
    "isoCode": "RO",
    "continent": "Europe"
  },
  "RU": {
    "name": "Russia",
    "isoCode": "RU",
    "continent": "Europe"
  },
  "RW": {
    "name": "Rwanda",
    "isoCode": "RW",
    "continent": "Africa"
  },
  "KN": {
    "name": "Saint Kitts and Nevis",
    "isoCode": "KN",
    "continent": "North America"
  },
  "LC": {
    "name": "Saint Lucia",
    "isoCode": "LC",
    "continent": "North America"
  },
  "VC": {
    "name": "Saint Vincent and the Grenadines",
    "isoCode": "VC",
    "continent": "North America"
  },
  "WS": {
    "name": "Samoa",
    "isoCode": "WS",
    "continent": "Oceania"
  },
  "SM": {
    "name": "San Marino",
    "isoCode": "SM",
    "continent": "Europe"
  },
  "ST": {
    "name": "Sao Tome and Principe",
    "isoCode": "ST",
    "continent": "Africa"
  },
  "SA": {
    "name": "Saudi Arabia",
    "isoCode": "SA",
    "continent": "Asia"
  },
  "SN": {
    "name": "Senegal",
    "isoCode": "SN",
    "continent": "Africa"
  },
  "RS": {
    "name": "Serbia",
    "isoCode": "RS",
    "continent": "Europe"
  },
  "SC": {
    "name": "Seychelles",
    "isoCode": "SC",
    "continent": "Africa"
  },
  "SL": {
    "name": "Sierra Leone",
    "isoCode": "SL",
    "continent": "Africa"
  },
  "SG": {
    "name": "Singapore",
    "isoCode": "SG",
    "continent": "Asia"
  },
  "SK": {
    "name": "Slovakia",
    "isoCode": "SK",
    "continent": "Europe"
  },
  "SI": {
    "name": "Slovenia",
    "isoCode": "SI",
    "continent": "Europe"
  },
  "SB": {
    "name": "Solomon Islands",
    "isoCode": "SB",
    "continent": "Oceania"
  },
  "SO": {
    "name": "Somalia",
    "isoCode": "SO",
    "continent": "Africa"
  },
  "ZA": {
    "name": "South Africa",
    "isoCode": "ZA",
    "continent": "Africa"
  },
  "SS": {
    "name": "South Sudan",
    "isoCode": "SS",
    "continent": "Africa"
  },
  "ES": {
    "name": "Spain",
    "isoCode": "ES",
    "continent": "Europe"
  },
  "LK": {
    "name": "Sri Lanka",
    "isoCode": "LK",
    "continent": "Asia"
  },
  "SD": {
    "name": "Sudan",
    "isoCode": "SD",
    "continent": "Africa"
  },
  "SR": {
    "name": "Suriname",
    "isoCode": "SR",
    "continent": "South America"
  },
  "SE": {
    "name": "Sweden",
    "isoCode": "SE",
    "continent": "Europe"
  },
  "CH": {
    "name": "Switzerland",
    "isoCode": "CH",
    "continent": "Europe"
  },
  "SY": {
    "name": "Syria",
    "isoCode": "SY",
    "continent": "Asia"
  },
  "TW": {
    "name": "Taiwan",
    "isoCode": "TW",
    "continent": "Asia"
  },
  "TJ": {
    "name": "Tajikistan",
    "isoCode": "TJ",
    "continent": "Asia"
  },
  "TZ": {
    "name": "Tanzania",
    "isoCode": "TZ",
    "continent": "Africa"
  },
  "TH": {
    "name": "Thailand",
    "isoCode": "TH",
    "continent": "Asia"
  },
  "TG": {
    "name": "Togo",
    "isoCode": "TG",
    "continent": "Africa"
  },
  "TO": {
    "name": "Tonga",
    "isoCode": "TO",
    "continent": "Oceania"
  },
  "TT": {
    "name": "Trinidad and Tobago",
    "isoCode": "TT",
    "continent": "North America"
  },
  "TN": {
    "name": "Tunisia",
    "isoCode": "TN",
    "continent": "Africa"
  },
  "TR": {
    "name": "Türkiye",
    "isoCode": "TR",
    "continent": "Asia"
  },
  "TM": {
    "name": "Turkmenistan",
    "isoCode": "TM",
    "continent": "Asia"
  },
  "TV": {
    "name": "Tuvalu",
    "isoCode": "TV",
    "continent": "Oceania"
  },
  "UG": {
    "name": "Uganda",
    "isoCode": "UG",
    "continent": "Africa"
  },
  "UA": {
    "name": "Ukraine",
    "isoCode": "UA",
    "continent": "Europe"
  },
  "AE": {
    "name": "United Arab Emirates",
    "isoCode": "AE",
    "continent": "Asia"
  },
  "GB": {
    "name": "United Kingdom",
    "isoCode": "GB",
    "continent": "Europe"
  },
  "US": {
    "name": "United States",
    "isoCode": "US",
    "continent": "North America"
  },
  "UY": {
    "name": "Uruguay",
    "isoCode": "UY",
    "continent": "South America"
  },
  "UZ": {
    "name": "Uzbekistan",
    "isoCode": "UZ",
    "continent": "Asia"
  },
  "VU": {
    "name": "Vanuatu",
    "isoCode": "VU",
    "continent": "Oceania"
  },
  "VA": {
    "name": "Vatican City",
    "isoCode": "VA",
    "continent": "Europe"
  },
  "VE": {
    "name": "Venezuela",
    "isoCode": "VE",
    "continent": "South America"
  },
  "VN": {
    "name": "Vietnam",
    "isoCode": "VN",
    "continent": "Asia"
  },
  "YE": {
    "name": "Yemen",
    "isoCode": "YE",
    "continent": "Asia"
  },
  "ZM": {
    "name": "Zambia",
    "isoCode": "ZM",
    "continent": "Africa"
  },
  "ZW": {
    "name": "Zimbabwe",
    "isoCode": "ZW",
    "continent": "Africa"
  }
}

export const countryNameToIsoMap = Object.fromEntries(
  Object.entries(countries).map(([key, value]) => [value.name, key])
);