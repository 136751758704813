import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  IconButton,
  Box,
  Typography,
  Modal,
  FormControlLabel,
  FormLabel,
  FormControl,
  Radio,
  MenuItem,
  Button,
  Grid,
  useMediaQuery,
  Stack,
  Autocomplete,
  Select,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc, setDoc, Timestamp } from 'firebase/firestore';
import { db } from 'services/firebase';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/auth/authSlice';
import { StyledInput } from 'assets/styles/GlobalStyle';
import './ReportForm.styled.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import { validationFormSchema } from 'utils/validationSchema';
import { countries } from 'utils/constants';
import { typeHazards } from 'utils/type';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { nanoid } from 'nanoid';
import SubMapComponent from 'components/common/Map/SubMapComponent';
const MilitaryReportForm = ({
  openMapModal,
  selectedMapLocation,
  selectedLatLng,
  setSelectedLatLng,
  locationInfo,
  userLocation,
}) => {
  const { id } = useSelector(state => state.report);
  const [, setSelectedLocation] = useState(null);
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const [openSubmit, setOpenSubmit] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenSubmit(false);
    resetForm();
    navigate('/account/my-reports');
  };
  const [loading, setLoading] = useState(false);
  const [inputVideos, setInputVideos] = useState([]);

  const storage = getStorage();

  const handleInputVideoChange = async e => {
    const videoFiles = Array.from(e.currentTarget.files);
    setFieldValue('video', [...(values.video || []), ...videoFiles]);
    setInputVideos([...inputVideos, ...videoFiles]);
  };

  const typeHazard = typeHazards.find(
    typeHazard => typeHazard.id === 'militaryActivity'
  );

  const [, setOpenType] = useState(false);
  const [selectedTypeId, setSelectedTypeId] = useState('');

  const handleSelectType = id => {
    const selectedItem = typeHazard.items.find(item => item.id === id);
    setSelectedTypeId(id);
    setFieldValue('hazardType', selectedItem ? selectedItem.id : '');
    setOpenType(true);
  };

  const {
    handleSubmit,
    handleChange,
    values,
    setFieldValue,
    errors,
    setErrors,
    resetForm,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      timeObserved: null,
      location: '',
      contactMethod: '',
      contactNumber: '',
      hazardType: '',
      resourcesThreatened: '',
      protective: '',
      priority: '0',
      size: '',
      unitCountry: '',
      uniformDescription: '',
      equipmentObserved: '',
      originalSocialMediaLink: '',
      photo: [],
      video: [],
      pdf: [],
      selectedLatLng,
    },
    validationSchema: validationFormSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        const writeUserData = async (values, selectedLatLng) => {
          const docData = {
            latitude: selectedLatLng.lat,
            longitude: selectedLatLng.lng,
            city: locationInfo.city,
            state: locationInfo.state,
            country: locationInfo.country,
            timeObserved: values.timeObserved
              ? Timestamp.fromDate(new Date(values.timeObserved))
              : Timestamp.fromDate(new Date()),
            contactMethod: values.contactMethod || null,
            contactNumber: values.contactNumber || null,
            unitCountry: values.unitCountry || 'unknown',
            size: parseInt(values.size) || null,
            equipment: values.equipmentObserved || null,
            priority: parseInt(values.priority),
            description: values.uniformDescription || null,
            reportingApp: 'Sleuth Net',
            hazardType: values.hazardType,
            photoUrl: [],
            videoUrl: [],
            docPdfUrl: [],
            originalSocialMediaLink: values.originalSocialMediaLink || null,
            timeReported: Timestamp.fromDate(new Date()),
            reportType: 'militaryActivity',
            reportName: 'Military Activity',
            reportId: '',
            originalReportUserId: user,
            archived: false,
            verificationStatus: 'notStarted',
            verifiedByUserId: null,
            verificationTimestamp: Timestamp.fromDate(new Date()),
            verificationUpVotes: null,
            verificationDownVotes: null,
            initialSafetyStatus: 'unknown',
            verifiedSafetyStatus: null,
            closedForComments: false,
            closedForUpdates: false,
            marineName: '',
            placeType: '',
          };

          try {
            const docRef = await addDoc(collection(db, 'Reports'), {
              ...docData,
              // reportId: docRef.id
            });

            const uploadPromises = values.photo.map(async (photo, index) => {
              const photoName = `${user}_${index + 1}`;
              const storageRef = ref(
                storage,
                `${id}Report/${docRef.id}/${photoName}`
              );

              await uploadBytes(storageRef, photo);
              return getDownloadURL(storageRef);
            });

            const photoUrls = await Promise.all(uploadPromises);

            if (inputVideos.length > 0) {
              const videoUrls = [];
              let videoCount = 1;
              for (const video of inputVideos) {
                const videoName = `${user}_video_${videoCount}`;
                const videoStorageRef = ref(
                  storage,
                  `militaryActivityReport/${docRef.id}/${videoName}`
                );
                await uploadBytes(videoStorageRef, video);
                const videoUrl = await getDownloadURL(videoStorageRef);
                videoUrls.push(videoUrl);
                videoCount++;
              }
              values.videoUrl = videoUrls;
            }

            const uploadPdfPromises = values.pdf.map(async (pdf, index) => {
              const pdfName = `${user}_pdf_${index + 1}`;
              const storageRef = ref(
                storage,
                `militaryActivityReport/${docRef.id}/${pdfName}`
              );

              await uploadBytes(storageRef, pdf);
              return getDownloadURL(storageRef);
            });

            const pdfUrls = await Promise.all(uploadPdfPromises);

            await setDoc(
              docRef,
              {
                reportId: docRef.id,
                photoUrl: photoUrls || [],
                videoUrl: values.videoUrl || [],
                docPdfUrl: pdfUrls || [],
              },
              { merge: true }
            );

            console.log('Document written with ID: ', docRef.id);
          } catch (error) {
            console.error('Error adding document: ', error);
          }
        };

        await writeUserData(values, selectedLatLng);
        setLoading(false);
        setSelectedLocation(null);
        setOpenSubmit(true);
      } catch (error) {
        setLoading(false);
        toast.error('Error:', error, {
          duration: 4000,
          position: 'top-center',
        });
        console.log(error);
      }
    },
  });

  useEffect(() => {
    if (selectedLatLng && selectedLatLng.lat && selectedLatLng.lng) {
      setFieldValue('location', `${selectedLatLng.lat}, ${selectedLatLng.lng}`);
      setFieldValue('selectedLatLng', selectedLatLng);
    }
  }, [selectedLatLng, setFieldValue]);

  const handleReset = () => {
    resetForm();
    setSelectedLatLng(null);
  };
  const isFormValid =
    isValid &&
    dirty &&
    selectedLatLng &&
    selectedLatLng.lat &&
    selectedLatLng.lng;

  const isScreenLessThan900px = useMediaQuery('(max-width:899px)');

  return (
    <Box
      noValidate
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      className="ReportFormBox"
    >
      <Typography variant="h2">
        {t('ui:report.createReportTitle')}
        {': '}
        <strong>{t(`hazards:hazardName.${id}`)}</strong>
      </Typography>

      <Grid container>
        <Tooltip
          title={t('reports:reportForm.required')}
          placement="top"
          followCursor
        >
          <Grid item xs={12} md={6}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                required
                disableFuture
                ampm={false}
                label={`${t('ui:report.dateTime')} *`}
                name="timeObserved"
                value={values.timeObserved}
                onChange={date => setFieldValue('timeObserved', date)}
                slotProps={{ textField: { variant: 'standard' } }}
                sx={{
                  marginY: 2,
                  width: '100%',
                  height: '100%',
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Tooltip>
        {isScreenLessThan900px && (
          <Box
            sx={{
              flex: 1,
              minWidth: 320,
              width: '100%',
              height: 200,
              bgcolor: '#1d1d1d',
              display: 'block',
            }}
          >
            <SubMapComponent
              defaultCenter={selectedMapLocation || userLocation}
              position={selectedMapLocation}
            />
          </Box>
        )}
        <Grid item container spacing={2} sx={{ mb: 2 }}>
          <Tooltip
            title={t('reports:reportForm.required')}
            placement="top"
            followCursor
          >
            <Grid item xs={12} md={8}>
              {selectedMapLocation ? (
                <StyledInput
                  fullWidth
                  required
                  variant="standard"
                  label={t('ui:report.location')}
                  name="location"
                  value={
                    selectedLatLng
                      ? `${selectedLatLng.lat}, ${selectedLatLng.lng}`
                      : values.location
                  }
                />
              ) : (
                <Typography
                  sx={{
                    width: '100%',
                    height: '100%',
                    marginTop: 1,
                    textAlign: 'normal',
                  }}
                >
                  {`${t('ui:report.location')} *`}
                </Typography>
              )}
            </Grid>
          </Tooltip>
          <Grid item xs={12} md={4}>
            <Button
              fullWidth
              variant="outlined"
              onClick={openMapModal}
              children={t('ui:button.selectLocation')}
              style={{ height: '100%' }}
            />
          </Grid>
        </Grid>
        <FormControl component="fieldset" fullWidth>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'normal',
            }}
          >
            <FormLabel component="legend" sx={{ mr: 2 }}>
              {t('ui:report.contactMethod')}
            </FormLabel>

            <Box>
              <FormControlLabel
                style={{ fontSize: '14px' }}
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Do Not Contact'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Do Not Contact"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>
                    {t('ui:report.doNotContact')}
                  </span>
                }
              />

              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Phone'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Phone"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>
                    {t('ui:report.phone')}
                  </span>
                }
              />
              <FormControlLabel
                control={
                  <Radio
                    size="small"
                    checked={values.contactMethod === 'Radio Frequency'}
                    onChange={handleChange}
                    name="contactMethod"
                    value="Radio Frequency"
                  />
                }
                label={
                  <span style={{ fontSize: '14px' }}>
                    {t('ui:report.radio')}
                  </span>
                }
              />
            </Box>
          </Box>

          <StyledInput
            sx={{
              mb: 2,
              width: '60%',
            }}
            variant="standard"
            label={t('ui:report.choiceMethod')}
            name="contactNumber"
            onChange={handleChange}
            value={values.contactNumber}
            disabled={values.contactMethod === 'Do Not Contact'}
          />
        </FormControl>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={5}>
            <Select
              fullWidth
              className="CustomSelect"
              id="hazardType"
              name="hazardType"
              value=""
              displayEmpty
              onChange={e => handleSelectType(e.target.value)}
            >
              <MenuItem value="">
                {t('ui:report.militaryHazardSelection')}
              </MenuItem>
              {typeHazard &&
                typeHazard.items.map(item => (
                  <MenuItem key={item.id} value={item.id}>
                    {t(`hazards:hazard.${typeHazard.id}.items.${item.id}`)}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
          <Tooltip
            title={t('reports:reportForm.required')}
            placement="top"
            followCursor
          >
            <Grid item xs={12} md={7}>
              <StyledInput
                sx={{
                  mb: 2,
                }}
                required
                disabled
                label={t('ui:report.hazardSelection')}
                variant="standard"
                value={
                  selectedTypeId
                    ? t(
                        `hazards:hazard.militaryActivity.items.${selectedTypeId}`
                      )
                    : ''
                }
              />
            </Grid>{' '}
          </Tooltip>
          {selectedTypeId === 'other' && (
            <Grid item xs={12}>
              <StyledInput
                required
                fullWidth
                variant="standard"
                label={t('ui:report.type')}
                name="hazardType"
                value={values.hazardType}
                onChange={handleChange}
              />
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Autocomplete
              options={Object.values(countries)}
              getOptionLabel={option =>
                t(`countries:countries.${option.isoCode}.name`)
              }
              renderInput={params => (
                <StyledInput
                  {...params}
                  label={t('ui:report.unit')}
                  variant="standard"
                  fullWidth
                  required
                />
              )}
              value={
                Object.values(countries).find(
                  country => country.name === values.unitCountry
                ) || null
              }
              onChange={(event, newValue) => {
                setFieldValue('unitCountry', newValue ? newValue.name : '');
              }}
              isOptionEqualToValue={(option, value) => option.name === value} // перевіряємо за англійською назвою
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.isoCode}>
                  <Typography variant="body1">
                    {t(`countries:countries.${option.isoCode}.name`)}{' '}
                  </Typography>
                </MenuItem>
              )}
              renderTags={(selected, getTagProps) => (
                <Typography variant="body1">
                  {t(`countries:countries.${selected.isoCode}.name`)}{' '}
                </Typography>
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <StyledInput
              fullWidth
              variant="standard"
              id="size"
              label={t('ui:report.size')}
              name="size"
              autoComplete="off"
              value={values.size}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="uniformDescription"
            label={t('ui:report.addComment')}
            name="uniformDescription"
            autoComplete="off"
            value={values.uniformDescription}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            variant="standard"
            id="equipmentObserved"
            label={t('ui:report.equipmentObserved')}
            name="equipmentObserved"
            autoComplete="off"
            value={values.equipmentObserved}
            onChange={handleChange}
            sx={{
              mb: 2,
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'normal',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  mr: 2,
                }}
              >
                {t('ui:report.priority')}
              </FormLabel>
              <Box>
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '2'}
                      onChange={handleChange}
                      name="priority"
                      value={2}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('ui:report.high')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '1'}
                      onChange={handleChange}
                      name="priority"
                      value={1}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('ui:report.medium')}
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Radio
                      size="small"
                      checked={values.priority === '0'}
                      onChange={handleChange}
                      name="priority"
                      value={0}
                    />
                  }
                  label={
                    <span style={{ fontSize: '14px' }}>
                      {t('ui:report.low')}
                    </span>
                  }
                />
              </Box>
            </Box>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <StyledInput
            fullWidth
            type="url"
            variant="standard"
            id="originalSocialMediaLink"
            name="originalSocialMediaLink"
            label={t('ui:report.socialMediaLink')}
            autoComplete="off"
            value={values.originalSocialMediaLink}
            onChange={handleChange}
            sx={{
              mb: 1,
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.photo ? 2 : 1,
              borderColor: errors.photo ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('photo', []);
                setErrors({ ...errors, photo: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {values.photo.length === 0 ? (
                <Typography
                  className="UploadPlaceholder"
                  children={t('ui:report.uploadPhoto')}
                />
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {values.photo.map(file => (
                    <div key={file.name}> - {file.name}; </div>
                  ))}
                </div>
              )}
            </div>
            {errors.photo && (
              <span className="UploadError">{errors.photo} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.photo ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('ui:button.photo')} </Typography>
              <input
                hidden
                name="photo"
                id="photo"
                type="file"
                accept=".jpg, .jpeg, .png"
                multiple
                onChange={e => {
                  const files = Array.from(e.currentTarget.files);
                  setFieldValue('photo', [...(values.photo || []), ...files]);
                }}
              />
            </Button>
          </Box>
        </Grid>
        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.video ? 2 : 1,
              borderColor: errors.video ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('video', []);
                setInputVideos([]);
                setErrors({ ...errors, video: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {inputVideos.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {inputVideos.map(video => (
                    <div key={nanoid()}>{video.name}</div>
                  ))}
                </div>
              ) : (
                <Typography
                  className="UploadPlaceholder"
                  children={t('ui:report.uploadVideo')}
                />
              )}
            </div>
            {errors.video && (
              <span className="UploadError">{errors.video} </span>
            )}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.video ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}> {t('ui:button.video')}</Typography>

              <input
                hidden
                name="video"
                id="video"
                type="file"
                accept=".mp4, .avi, .mkv"
                onChange={handleInputVideoChange}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item md={12} xs={12}>
          <Box
            sx={{
              borderRadius: 0,
              position: 'relative',
              borderWidth: errors.pdf ? 2 : 1,
              borderColor: errors.pdf ? '#d32f2f' : '#133a56',
              borderBottom: 'solid 1px #2196f3',
              my: 1,
            }}
          >
            <IconButton
              onClick={() => {
                setFieldValue('pdf', []);
                setErrors({ ...errors, pdf: '' });
              }}
            >
              <ClearIcon />
            </IconButton>
            <div className="UploadInput">
              {values.pdf.length > 0 ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  {values.pdf.map(file => (
                    <div key={file.name}>{file.name}</div>
                  ))}
                </div>
              ) : (
                <Typography
                  className="UploadPlaceholder"
                  children={t('ui:report.uploadPdf')}
                />
              )}
            </div>
            {errors.pdf && <span className="UploadError">{errors.pdf}</span>}
            <Button
              variant="outlined"
              component="label"
              startIcon={<AttachFileIcon />}
              sx={{
                borderColor: errors.pdf ? '#d32f2f' : '',
                width: 160,
              }}
            >
              <Typography sx={{ mr: 1 }}>{t('ui:button.pdf')}</Typography>
              <input
                hidden
                name="pdf"
                id="pdf"
                type="file"
                accept=".pdf"
                multiple
                onChange={e => {
                  const files = Array.from(e.currentTarget.files);
                  setFieldValue('pdf', [...(values.pdf || []), ...files]);
                }}
              />
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Stack spacing={2} direction="row" marginTop={2}>
            <Button
              variant="contained"
              type="submit"
              disabled={!isFormValid}
              style={{ minWidth: 176 }}
            >
              {loading ? (
                <CircularProgress color={'secondary'} size={24} />
              ) : (
                `${t('ui:button.submit')}`
              )}
            </Button>
            <Button
              variant="outlined"
              type="reset"
              onClick={handleReset}
              // disabled={!(formik.isValid && formik.dirty)}
              children={t('ui:button.clear')}
            />
          </Stack>
        </Grid>
      </Grid>
      <Modal
        open={openSubmit}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="SubmitedReporttModal">
          <IconButton
            aria-label="close"
            sx={{
              zIndex: 1,
              position: 'absolute',
              top: '-20%',
              left: '100%',
              transition: 'background-color 0.3s',
              ':hover': {
                backgroundColor: '#2196f3',
              },
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ fontWeight: 'bold', mb: 2 }}
          >
            {t(`hazards:hazardName.${id}`)}
          </Typography>

          <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 2 }}>
            {t('reports:reportForm.reportSubDesc')}{' '}
          </Typography>

          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{
              transition: 'background-color 0.3s',
              ':hover': {
                backgroundColor: '#2196f3',
              },
            }}
          >
            {t('ui:button.close')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default MilitaryReportForm;
