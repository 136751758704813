// MapComponent.jsx

import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectUser, selectUserLocation } from 'redux/auth/authSlice';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import {
  APIProvider,
  Map,
  InfoWindow,
  useMap,
} from '@vis.gl/react-google-maps';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Tooltip,
  Popper,
  ClickAwayListener,
} from '@mui/material';
import {
  getUserLocation,
  fetchUserLocationByIP,
} from 'services/getUserLocation';
import PhotoViewer from 'components/ui/Modal/PhotoViewer';
import VideoViewer from 'components/ui/Modal/VideoViewer';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import './Map.style.css';
import { languageSelectors } from 'redux/language';

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const MapComponent = ({
  endpoints,
  endpointData,
  endpointChecks,
  // showRecentReports,
  center,
  }) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  const userGeoLocation = useSelector(selectUserLocation);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lang = useSelector(languageSelectors.getLanguage);
  const [userLocation, setUserLocation] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openMedia, setOpenMedia] = useState(false);
  const [photoViewerData, setPhotoViewerData] = useState({
    photos: [],
    isOpen: false,
  });
  const [videoViewerData, setVideoViewerData] = useState({
    videos: [],
    isOpen: false,
  });
  const [countryCenter, setCountryCenter] = useState(center);

  const dateSeconds =
    selectedMarker?.data?.timeObserved?.seconds ||
    selectedMarker?.data?.dateTime?.seconds ||
    selectedMarker?.data?.timeReported?.seconds;

  const date = dateSeconds ? new Date(dateSeconds * 1000) : new Date();

  const formatDate = (date, lang) => {
    let options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };

    if (lang === 'ua') {
      options.locale = 'uk-UA';
    } else {
      options.locale = 'en-US';
    }

    return date.toLocaleDateString(options.locale, options);
  };

  const formattedDate = formatDate(date, lang);

  const formattedTime =
    date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: lang === 'ua' ? false : true,
    }) || 'Unknown Time';

  const formattedDateTime = dateSeconds
    ? `${formattedDate} ${formattedTime}`
    : '';

  console.log('id', selectedMarker?.data.reportId);

  

  useEffect(() => {
    async function fetchUserLocation() {
      let storedLocation = localStorage.getItem('userLocation');

      if (storedLocation) {
        setUserLocation(JSON.parse(storedLocation));
      } else {
        // First try to get geolocation if the user grants permission
        const location = await getUserLocation();
        if (location) {
          setUserLocation(location);
          localStorage.setItem('userLocation', JSON.stringify(location));
        } else {
          // If geolocation permission is denied or fails, use IP-based location
          const locationByIP = await fetchUserLocationByIP();
          if (locationByIP) {
            setUserLocation(locationByIP);
            localStorage.setItem('userLocation', JSON.stringify(locationByIP));
          } else {
            console.error('Could not get user location by IP');
          }
        }
      }
    }

    fetchUserLocation();
  }, [userGeoLocation]);

  useEffect(() => {
    async function updateGeolocation() {
      const geolocation = await getUserLocation();

      if (geolocation) {
        setUserLocation(geolocation);
        localStorage.setItem('userLocation', JSON.stringify(geolocation));
      }
    }

    // Update localStorage with geolocation if granted after IP-based location
    if (userGeoLocation && (!userLocation || userLocation.source === 'ip')) {
      updateGeolocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGeoLocation]);

  useEffect(() => {
    if (userLocation) {
      localStorage.setItem('userLocation', JSON.stringify(userLocation));
    }
  }, [userLocation]);

  const filteredReports = useMemo(() => {
    return endpoints.reduce((filteredData, { id }) => {
      if (endpointChecks[id]) {
        const reports = endpointData[id] || [];
        filteredData[id] = reports;
      }

      return filteredData;
    }, {});
  }, [endpointChecks, endpointData, endpoints]);

  useEffect(() => {
    if (center) {
      setCountryCenter(center);
    }
  }, [center]);

  const handleMarkerClick = (event, markerPosition, data) => {
    setSelectedMarker({ position: markerPosition, data });
  };

  const handleInfoWindowClose = () => {
    setSelectedMarker(null);
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setOpenMedia(prev => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMedia(false);
  };

  const closePhotoViewer = () => {
    setPhotoViewerData({ photos: [], isOpen: false });
  };

  const closeVideoViewer = () => {
    setVideoViewerData({ videos: [], isOpen: false });
  };

  const handleViewReportClick = report => {
    dispatch(setSelectedReport(report));
    navigate(`/report/view/${report.reportId || report.eventId}`);
  };

  const getIconUrl = (id, type) => {
    // const safeType = type ?? 'unknown';
    let iconUrl;
    try {
      // Try to load the icon based on the 'type' field
      iconUrl = require(`assets/img/typeofendpoints/${type}.png`);
    } catch (error) {
      iconUrl = require(`assets/img/typeofendpoints/${id}.png`);
    }
    return iconUrl;
  };

  if (!API_KEY) {
    return (
      <Box className="containerStyle">
        <CircularProgress color="primary" size={60} />
        <p>Error: Missing API Key</p>
      </Box>
    );
  }


  return (
    <Box className="mapMapBox">
      {userLocation ? (
        <Box className="mapContainer">
          <APIProvider apiKey={API_KEY}>
            <Map
              className="containerStyle"
              defaultCenter={countryCenter || userLocation}
              defaultZoom={7}
              gestureHandling={'greedy'}
              options={{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: true,
                streetViewControl: true,
                rotateControl: true,
                fullscreenControl: true,
              }}
            >
              <Markers
                points={filteredReports}
                onMarkerClick={handleMarkerClick}
                getIconUrl={getIconUrl || undefined}
              />

              {selectedMarker && (
                <InfoWindow
                  position={selectedMarker.position}
                  onCloseClick={handleInfoWindowClose}
                  options={{ pixelOffset: new window.google.maps.Size(0, -30) }}
                  className="gm-style"
                >
                  <Box
                    sx={{
                      color: 'black',
                      display: 'block',
                      overflow: 'hidden',
                      textAlign: 'left',
                      fontSize: '16px',
                    }}
                  >
                    <div>
                      <Typography
                        variant="h3"
                        color={'black'}
                        fontWeight={500}
                        children={t(
                          `hazards:hazard.${selectedMarker.data.reportType}.name`
                        )}
                      />
                    </div>
                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        mb={1}
                        children={
                          selectedMarker.data.hazardType
                            ? t(
                                `hazards:hazard.${selectedMarker.data.reportType}.items.${selectedMarker.data.hazardType}`
                              )
                            : selectedMarker.data.bombType
                        }
                      />
                    </div>

                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        textTransform={'uppercase'}
                        fontWeight={400}
                        mb={1}
                        children={`${t('ui:map.verificationStatus')}:  ${
                          selectedMarker.data.verificationStatus
                            ? t(
                                `ui:map.${selectedMarker.data.verificationStatus}`
                              )
                            : t('ui:map.inProgress')
                        }`}
                      />
                    </div>

                    <div>
                      <Typography
                        variant="body2"
                        color={'black'}
                        textTransform={'uppercase'}
                        fontWeight={400}
                        mb={1}
                        children={`${t('ui:map.safetyStatus')}:  ${
                          selectedMarker.data.safetyStatus
                            ? t(`ui:map.${selectedMarker.data.safetyStatus}`)
                            : t('ui:map.unknown')
                        }`}
                      />
                    </div>

                    <div>
                      <Typography
                        variant="overline"
                        sx={{
                          color: 'black',
                          mb: 1,
                          textTransform: 'unset',
                        }}
                        children={formattedDateTime}
                      />
                    </div>

                    <Box justifyContent={'space-between'}>
                      <Tooltip title={t('ui:button.openReport')}>
                        <Button
                          variant="contained"
                          // fullWidth
                          sx={{
                            mr: 1,
                            fontSize: '12px',
                            fontWeight: 600,
                            borderRadius: 2,
                            width: '132px',
                          }}
                          onClick={() =>
                            handleViewReportClick(selectedMarker.data)
                          }
                        >
                          {t('ui:button.openReport')}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        title={
                          user ? t('ui:map.userMedia') : t('ui:map.noUserMedia')
                        }
                      >
                        <Button
                          variant="outlined"
                          // fullWidth
                          sx={{
                            fontSize: '12px',
                            fontWeight: 600,
                            color: '#2196F3 !important',
                            borderRadius: 2,
                            width: '132px',
                          }}
                          onClick={handleClick}
                        >
                          {t('ui:button.viewMedia')}
                        </Button>
                      </Tooltip>
                    </Box>
                    {user && (
                      <Popper
                        open={openMedia}
                        anchorEl={anchorEl}
                        placement="top-start"
                        style={{ zIndex: 1300 }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <Box
                            sx={{
                              borderRadius: 4,
                              p: 2,
                              bgcolor: '#1d1d1d',
                              fontWeight: 'bold',
                            }}
                          >
                            {selectedMarker?.data?.videoUrl?.length > 0 ||
                            selectedMarker?.data?.photoUrl?.length > 0 ? (
                              <Box
                                display="flex"
                                flexDirection="column"
                                alignItems="flex-start"
                              >
                                {selectedMarker?.data?.videoUrl?.length > 0 &&
                                  selectedMarker.data.videoUrl !== 'empty' && (
                                    <div>
                                      <p
                                        style={{
                                          marginRight: 16,
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setVideoViewerData({
                                            videos:
                                              selectedMarker.data.videoUrl,
                                            isOpen: true,
                                          })
                                        }
                                      >
                                        {t('ui:map.video')}
                                      </p>
                                    </div>
                                  )}
                                <VideoViewer
                                  open={videoViewerData.isOpen}
                                  onClose={closeVideoViewer}
                                  videos={videoViewerData.videos}
                                />
                                {selectedMarker?.data?.photoUrl?.length > 0 &&
                                  selectedMarker.data.photoUrl !== 'empty' && (
                                    <div>
                                      <p
                                        style={{
                                          cursor: 'pointer',
                                        }}
                                        onClick={() =>
                                          setPhotoViewerData({
                                            photos:
                                              selectedMarker.data.photoUrl,
                                            isOpen: true,
                                          })
                                        }
                                      >
                                        {t('ui:map.photo')}
                                      </p>
                                    </div>
                                  )}
                                <PhotoViewer
                                  photos={photoViewerData.photos}
                                  open={photoViewerData.isOpen}
                                  onClose={closePhotoViewer}
                                />
                              </Box>
                            ) : (
                              <Typography>{t('ui:map.noMedia')}</Typography>
                            )}
                          </Box>
                        </ClickAwayListener>
                      </Popper>
                    )}
                  </Box>
                </InfoWindow>
              )}
            </Map>
          </APIProvider>
        </Box>
      ) : (
        <Box className="containerStyle">
          <CircularProgress color="primary" size={60} />
          <Typography>{t('ui:map.loadingLocation')}</Typography>
        </Box>
      )}
    </Box>
  );
};

const Markers = ({ points, onMarkerClick, getIconUrl }) => {
  const { t } = useTranslation();
  const map = useMap();
  const [markers, setMarkers] = useState([]);
  const clusterer = useRef(null);

  useEffect(() => {
    if (!map) return;

    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(markers);
  }, [markers]);

  useEffect(() => {
    if (!map) return;

    const newMarkers = [];

    Object.entries(points).forEach(([id, reports]) => {
      reports.forEach(report => {
        const marker = new window.google.maps.Marker({
          position: {
            lat: Number(report.latitude),
            lng: Number(report.longitude),
          },
          title: `${new Date(
            report.timeObserved.seconds * 1000
          ).toLocaleString()} ⚠️ ${
            report.bombType ||
            (report.hazardType
              ? t(
                  `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                )
              : t('ui:map.undefined'))
          }`,
          icon: {
            url: getIconUrl(id, report.hazardType),
            scaledSize: new window.google.maps.Size(40, 40),
          },
        });

        marker.addListener('click', event => {
          onMarkerClick(
            event,
            {
              lat: Number(report.latitude),
              lng: Number(report.longitude),
            },
            {
              id,
              ...report,
            }
          );
          map.setZoom(15);
          map.setCenter(marker.getPosition());
        });

        newMarkers.push(marker);
      });
    });

    setMarkers(newMarkers);
  }, [points, map, getIconUrl, onMarkerClick, t]);

  return null;
};

export default MapComponent;
