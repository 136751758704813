import { useState, useEffect } from 'react';
import {
  IconButton,
  Badge,
  MenuItem,
  Menu,
  Box,
  Typography,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Link } from 'react-router-dom';
import { getNearbyReports } from 'services/getNearbyReports';
import { useTranslation } from 'react-i18next';
import {
  fetchUserLocationByIP,
  getUserLocation,
} from 'services/getUserLocation';
import { reportDateTime } from 'utils/constants';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectNearbyReports,
  setNearbyReports,
} from 'redux/report/nearbyReportsSlice';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    top: 8,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

const NotificationIcon = ({ isUserLocationPermission, userId }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [displayedRows] = useState(3);
  const [displayAllReports, setDisplayAllReports] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleViewAll = () => setDisplayAllReports(!displayAllReports);
  const { nearbyReports } = useSelector(selectNearbyReports); // Fetch nearby reports
  const reportsCount = nearbyReports?.length;

  useEffect(() => {
    const fetchReports = async () => {
      const location = isUserLocationPermission
        ? await getUserLocation()
        : await fetchUserLocationByIP();

      if (location && location.lat && location.lng) {
        const unsubscribe = getNearbyReports(
          location.lat,
          location.lng,
          500, // Search radius
          userId,
          reports => {
            console.log('reports', reports);
            dispatch(setNearbyReports(reports));
          }
        );

        // Cleanup listener on component unmount
        return () => unsubscribe();
      } else {
        console.error('Invalid location data:', location);
      }
    };

    fetchReports();
  }, [dispatch, isUserLocationPermission, userId]);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t('ui:notifications.title')}>
        <IconButton
          id="notification-button"
          aria-controls={isMenuOpen ? 'notification-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpen ? 'true' : undefined}
          onClick={handleMenuOpen}
          disabled={reportsCount === 0}
        >
          <StyledBadge badgeContent={reportsCount} color="primary">
            <NotificationsIcon />
          </StyledBadge>
        </IconButton>
      </Tooltip>
      {anchorEl && nearbyReports && (
        <Menu
          id="notification-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          sx={{
            '& .MuiAvatar-root': {
              minWidth: 240,
              padding: 2,
              bgcolor: '#ffff',
              backgroundImage: 'none',
            },
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
        >
          <Box
            bgcolor={'#0000'}
            flexDirection={'column'}
            alignItems={'normal'}
            p={2}
          >
            <Typography fontWeight={600} textDecoration="underline">
              {t('ui:appBar.resourceNotify')}
            </Typography>
            {nearbyReports
              .slice(
                0,
                displayAllReports ? nearbyReports.length : displayedRows
              )
              .map(report => (
                <Box key={report.reportId}>
                  <MenuItem
                    sx={{ width: '100%' }}
                    component={Link}
                    to="/account/notifications"
                    onClick={() => {
                      console.log('Navigating with state:', {
                        nearbyReports,
                      });
                      handleMenuClose();
                    }}
                  >
                    <Box flexDirection={'column'} alignItems={'normal'}>
                      <Typography>
                        {t(
                          `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                        )}
                      </Typography>
                      <Typography variant="caption">
                        {reportDateTime(report.timeObserved.seconds * 1000)}
                      </Typography>
                    </Box>
                  </MenuItem>
                  <Divider orientation="horizontal" />
                </Box>
              ))}
            {nearbyReports.length > 3 && (
              <Button
                sx={{ display: 'block', marginLeft: 'auto' }}
                onClick={handleViewAll}
              >
                {displayAllReports
                  ? t('ui:button.hide')
                  : t('ui:button.viewAll')}
              </Button>
            )}
          </Box>
        </Menu>
      )}
    </>
  );
};

export default NotificationIcon;
