import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  orderBy,
  where,
  getDocs,
  limit,
} from 'firebase/firestore';
import { db } from 'services/firebase';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Divider,
  CircularProgress,
} from '@mui/material';
import NewspaperIcon from '@mui/icons-material/Newspaper';

export const NewsFeed = ({ trendingCountry }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setError('');

      try {
        let q;
        if (trendingCountry) {
          const formattedCountry =
            trendingCountry.charAt(0).toUpperCase() + trendingCountry.slice(1);

          q = query(
            collection(db, 'newsRss'),
            where('country', 'array-contains', formattedCountry),
            orderBy('date', 'desc'),
            limit(25)
          );
        } else {
          q = query(
            collection(db, 'newsRss'),
            orderBy('date', 'desc'),
            limit(25)
          );
        }

        const querySnapshot = await getDocs(q);

        const postArray = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            date: data.date.toDate(),
            title: data.title,
            url: data.url,
            description: data.description,
            country: Array.isArray(data.country)
              ? data.country.join(', ')
              : data.country,
          };
        });

        setPosts(postArray);
      } catch (err) {
        console.error('Firestore query error:', err);
        setError(`Failed to load news feed: ${err.message}`);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [trendingCountry]);

  const formatDate = date => {
    return new Date(date).toLocaleDateString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <Box sx={{ width: '100%', height: '100%', bgcolor: '#1D1D1D' }}>
      {!loading ? (
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 680,
            '& ul': { padding: 1 },
          }}
        >
          {posts.map(post => (
            <React.Fragment key={post.id}>
              <ListItem component="div" disablePadding>
                <ListItemButton
                  sx={{ direction: 'row', justifyContent: 'end' }}
                  component="a"
                  target="_blank"
                  href={post.url}
                >
                  <NewspaperIcon
                    color="secondary"
                    sx={{ alignSelf: 'flex-start', mt: '6px' }}
                  />
                  <ListItemText
                    primary={post.title}
                    primaryTypographyProps={{
                      ml: '8px',
                      textAlign: 'left',
                      lineHeight: '20px',
                    }}
                    secondary={
                      <Typography
                        sx={{
                          ml: '8px',
                          fontSize: '12px',
                          color: '#5B5B5B',
                          textAlign: 'left',
                          lineHeight: '8px',
                          mt: '10px',
                        }}
                      >
                        {formatDate(post.date)}
                      </Typography>
                    }
                  />
                </ListItemButton>
              </ListItem>
              <Divider sx={{ backgroundColor: '#101010' }} />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Box sx={{ width: '100%', mt: 3, justifyContent: 'center' }}>
          <CircularProgress color="primary" size={60} />
        </Box>
      )}
    </Box>
  );
};
