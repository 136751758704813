import {} from './SwitchLanguage.styled';
import { useDispatch, useSelector } from 'react-redux';
import i18next from 'i18next';
import usa from 'assets/img/usa.png';
import ukr from 'assets/img/ukraine.png';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import 'utils/i18next';
import json2mq from 'json2mq';
import { languageReducer, languageSelectors } from 'redux/language';
import { selectIsLoggin } from 'redux/auth/authSlice';

export const SwitchLanguage = () => {
  const dispatch = useDispatch();
  const lang = useSelector(languageSelectors.getLanguage);
  const { switchLanguage } = languageReducer;
  const isLoggedIn = useSelector(selectIsLoggin);
  const { t } = useTranslation();
  const matches = useMediaQuery(
    json2mq({
      minWidth: 811,
    })
  );
  function handleClick(e) {
    const newLang = lang === 'en' ? 'ua' : 'en';
    dispatch(switchLanguage(newLang));
    i18next.changeLanguage(newLang);
  }

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      {matches && (
        <Typography
          variant="text"
          fontFamily={'Montserrat'}
          fontSize={isLoggedIn ? 10 : 15}
          textTransform={'capitalize'}
        >
          {t('ui:topMenu.language')}
        </Typography>
      )}
      <Tooltip title={t('ui:topMenu.changeLanguage')}>
        <IconButton
          onClick={handleClick}
          sx={{ width: isLoggedIn ? 25 : 40, p: 0 }}
        >
          <img
            style={{
              width: isLoggedIn ? '25px' : '40px',
              height: isLoggedIn ? '25px' : '40px',
            }}
            src={lang === 'en' ? usa : ukr}
            alt={lang === 'en' ? 'English' : 'Українська'}
          />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
