import { useState, useEffect } from 'react';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { selectUser } from 'redux/auth/authSlice';
import { setSelectedReport } from 'redux/report/updateReportSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
  Typography,
  Table,
  TableRow,
  TableCell,
  IconButton,
  Button,
  useMediaQuery,
  TableBody,
  TableContainer,
  TableHead,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import json2mq from 'json2mq';
import './UserReports.styled.css';

export const UserSubmissions = () => {
  const { t, i18n } = useTranslation();
  const [endpointData, setEndpointData] = useState([]);
  const db = getFirestore();
  const userId = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayedReports, setDisplayedReports] = useState([]);
  const [displayAllNewReports] = useState(false);

  const [displayedRows] = useState(3);

  const matches = useMediaQuery(json2mq({ minWidth: 500 }));
  // const matchesTab = useMediaQuery(json2mq({ minWidth: 1440 }));

  useEffect(() => {
    const reportsRef = collection(db, 'Reports');
    const reportsQuery = query(
      reportsRef,
      where('originalReportUserId', '==', userId)
    );
    console.log('Your User ID:', userId);

    getDocs(reportsQuery).then(querySnapshot => {
      const data = [];
      querySnapshot.forEach(doc => {
        data.push({ id: doc.id, ...doc.data() });
      });
      console.log('Data Fetched: ', data);
      setEndpointData(data);
    });
  }, [db, userId]);

  useEffect(() => {
    if (endpointData.length === 0) return;

    const displayed = endpointData.filter(report => !report.archived);

    setDisplayedReports(displayed);
  }, [endpointData]);

  const handleEditClick = report => {
    dispatch(setSelectedReport(report));
    navigate('/edit-report');
  };

  const recentReports = displayedReports.filter(
    report =>
      !report.closedForUpdates && report.verificationStatus === 'notStarted'
  );

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 150 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>{t('reports:submission.report')}</TableCell>
            <TableCell>{t('reports:submission.dateAdded')}</TableCell>
            <TableCell align="center">
              {t('reports:submission.verifiedStatus')}
            </TableCell>
            <TableCell align="right">{t('reports:submission.edit')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {recentReports
            .slice(
              0,
              displayAllNewReports ? recentReports.length : displayedRows
            )
            .sort((a, b) => b.timeObserved - a.timeObserved)
            .map(report => (
              <TableRow key={report.id}>
                <TableCell>
                  <Typography variant="body1">
                    {t(`hazards:hazardName.${report.reportType}`)}:
                  </Typography>
                  <br />
                  <Typography variant="body1">
                    {report.typeBomb
                      ? report.typeBomb
                      : t(
                          `hazards:hazard.${report.reportType}.items.${report.hazardType}`
                        )}
                  </Typography>
                </TableCell>

                <TableCell>
                  <Typography>
                    {new Date(
                      Number(report.timeObserved.seconds * 1000)
                    ).toLocaleDateString(i18n.language, {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric',
                    })}
                  </Typography>
                </TableCell>

                {matches && (
                  <TableCell>
                    {report.verificationStatus === 'notStarted' ? (
                      <Typography
                        color="secondary"
                        children={t('ui:report.notVerified')}
                      />
                    ) : (
                      <Typography
                        color="green"
                        children={t('ui:report.verified')}
                      />
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {matches ? (
                    <Button
                      variant="outlined"
                      children={t('reports:submission.edit')}
                      onClick={() => handleEditClick(report)}
                    />
                  ) : (
                    <IconButton onClick={() => handleEditClick(report)}>
                      <OpenInFullIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
