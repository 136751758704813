import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  firstName: null,
  email: null,
  token: null,
  id: null,
  membershipLevel: null,
  geolocationPermission: null,
  receiveNotifications: null,
  isLoggedIn: false,
  isAdmin: false,
};
export const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUser(state, action) {
      state.firstName = action.payload.firstName;
      state.email = action.payload.email;
      state.token = action.payload.token;
      state.id = action.payload.id;
      state.membershipLevel = action.payload.membershipLevel;
      state.geolocationPermission = action.payload.geolocationPermission;
      state.receiveNotifications = action.payload.receiveNotifications;
      state.isLoggedIn = true;
      state.isAdmin = action.payload.isAdmin;
    },
    logOutUser(state) {
      state.firstName = null;
      state.email = null;
      state.token = null;
      state.id = null;
      state.membershipLevel = null;
      state.geolocationPermission = null;
      state.receiveNotifications = null;
      state.isLoggedIn = false;
      state.isAdmin = false;
    },
  },
});
export const selectUserName = state => state.user.firstName;

export const selectUser = state => state.user.id;

export const selectUserEmail = state => state.user.email;

export const selectUserMembershipLevel = state => state.user.membershipLevel;

export const selectUserLocation = state => state.user.geolocationPermission;

export const selectUserNotify = state => state.user.receiveNotifications;

export const selectIsLoggin = state => state.user.isLoggedIn;

export const selectIsAdmin = state => state.user.isAdmin;

export const { saveUser, logOutUser } = authSlice.actions;

export default authSlice.reducer;
